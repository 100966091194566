/*! AINTCSS - Alphero Inverted Triangle CSS// ============ */
[data-collapsed] {
    transition: height 0.2s ease-out;
}

[data-collapsed="true"] {
    height: 0;
    overflow: hidden;
}

[data-collapsed="false"] {
    height: auto;
}

@font-face {
    font-family: "overseer";
    src: url("./fonts/overseer.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

:root {
    font-size: 14px;
}

html {
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857;
    color: #58585a;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gm-style {
    font: 400 14px "Exo 2", sans-serif;
}

::-moz-selection {
    background: #e4f5eb;
}

::selection {
    background: #e4f5eb;
}

h1,
.h1 {
    font-weight: 500;
    font-size: 2.57143rem;
    line-height: 1.25;
}

h2,
.lead,
.DirectoryList-items a,
.dz-message,
.HeroValue-item-number,
.PageHead-budgetOverview,
.h2 {
    font-weight: 400;
    font-size: 1.71429rem;
    line-height: 1.25;
}

.HeroValue-item-text {
    font-weight: 600;
    line-height: 2.25;
}

.HeroValue-item-number-large {
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.5;
}

h3,
.FarmTable-keyValue-lead,
.SubTabs-item a,
.h3 {
    font-weight: 600;
    font-size: 1.28571rem;
    line-height: 1.27778;
}

h4,
.NutrientsLost--lg,
.SubMenu-item a,
.h4 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.42857;
}

h5,
.ActionsBar-status,
.ActionsBar-links,
.AnnualCheckboxes .Checkbox-label,
.BlockAreaTotals,
.Button--sm,
.CellGraph-labels li,
.CellGraph-legend,
.Footer-nav a,
.Footer-nav .a,
.Field-label,
.Fieldset-heading-dl dt,
.dz-remove,
.Select--sort-label,
[class*="IconLink--"],
.PanelTabs-hamburger-title,
.PanelTabs-item a,
.PanelTabs-item .a,
.SectionTabs-item a,
.SubMenu-item a,
.SubMenu-item .a,
.Panel-action-label,
.Table th,
.Table .th,
.td-key,
td.td-labelled .td-label,
.h5 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    h5,
    .ActionsBar-status,
    .ActionsBar-links,
    .AnnualCheckboxes .Checkbox-label,
    .BlockAreaTotals,
    .Button--sm,
    .CellGraph-labels li,
    .CellGraph-legend,
    .Footer-nav a,
    .Footer-nav .a,
    .Field-label,
    .Fieldset-heading-dl dt,
    .dz-remove,
    .Select--sort-label,
    [class*="IconLink--"],
    .PanelTabs-hamburger-title,
    .PanelTabs-item a,
    .PanelTabs-item .a,
    .SectionTabs-item a,
    .SubMenu-item a,
    .SubMenu-item .a,
    .Panel-action-label,
    .Table th,
    .td-key,
    td.td-labelled .td-label,
    .h5 {
        font-size: 0.89286rem;
    }
}

h6,
.Fieldset-heading-dl dd,
.NutrientsLost--sm,
.h6 {
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.5;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    h6,
    .Fieldset-heading-dl dd,
    .NutrientsLost--sm,
    .h6 {
        font-size: 0.89286rem;
    }
}

h1,
h2 {
    color: #364052;
    margin-top: 0.667em;
    margin-bottom: 0;
}

h3,
h4,
h5,
h6 {
    margin-top: 0.75em;
    margin-bottom: 0;
}

h3,
.h3 {
    color: #36975c;
}

h4,
h5,
h6 {
    color: #58585a;
}

a,
.a {
    text-decoration: none;
    color: #509bc2;
}

    a.is-active,
    a:hover,
    a:active,
    a:focus-visible,
    .a.is-active,
    .a:hover,
    .a:active,
    .a:focus-visible {
        color: #364052;
    }

.u-link {
    cursor: pointer !important;
}

.u-link-white {
    color: #fff;
    text-decoration: underline;
}

    .u-link-white:hover {
        color: #fff;
        text-decoration: underline;
    }

.p,
p {
    font-size: 1rem;
    margin-top: 0.5em;
    margin-bottom: 0;
}

.lead + .p,
.lead + p {
    margin-top: 30px;
}

.small,
small {
    font-size: 0.95rem;
    line-height: 1.33333;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .small,
    small {
        font-size: 0.89286rem;
    }
}

em,
i {
    font-style: italic;
}

strong,
b {
    font-weight: 600;
}

ul {
    list-style: none;
}

    ul.disc {
        list-style: disc !important;
    }

ol {
    list-style: decimal;
}

sub,
sup {
    font-size: 0.95rem;
    line-height: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    sub,
    sup {
        font-size: 0.89286rem;
    }
}

hr {
    border: none;
    margin: 10px 0 0;
    background-color: #eee;
    height: 1px;
}

.ActionsBar-status,
.ActionsBar-links,
.BlockAreaTotals,
.BlockList,
.Calendar-slot-menu,
.DirectoryList-filter,
.DirectoryList-items ul,
.FarmTable-supplementary-table,
.Footer-nav,
.List--templateIndex,
.Nav,
.PanelTabs-menu,
.SectionTabs,
.SubMenu,
.SubTabs,
.UtilityNav,
.UtilityNav-user-dropdown {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

[href="tel"] {
    white-space: nowrap;
}

.Button,
.Textarea textarea,
.Tile-title {
    font-weight: 500;
}

.Alert_label,
.BlockList-item,
.Collapsible-head,
.DirectoryList-filter a,
.FarmTable-key,
.FarmTable-supplementary-table-th,
.NCEIndicator div,
.NutrientsLost-indicator,
.NutrientsLost-n,
.NutrientsLost-ghg,
.NutrientsLost-p,
.Tile-title--large,
.UtilityNav,
.NutrientSummary {
    font-weight: 600;
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
    margin: 0;
}

button,
figure,
input,
keygen,
optgroup,
select,
textarea {
    font-family: "Exo 2", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 1.42857;
    margin: 0;
}

footer,
header,
main,
nav {
    display: block;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

hr {
    box-sizing: content-box;
    overflow: visible;
}

button,
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

.icon,
.dz-remove:before,
.dz-success-mark:before,
.dz-error-mark:before,
[class*="IconLink--"]:before,
[class*="IconLink--"]:after,
.Nav-item a:before,
.Nav-item .a:before,
.RoundButton:before,
.PanelTabs-item a:before,
.PanelTabs-item .a:before,
.SectionTabs-item a:before {
    font-family: "overseer" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24px;
}

.icon--sm {
    font-size: 18px;
}

.icon--md {
    font-size: 45px;
}

.icon--lg {
    font-size: 70px;
}

.icon--confirm {
    color: #ca5e0c !important;
}

.icon-target:before,
.IconLink--target:before {
    content: "\e9b3";
}

.IconLink--compressed:before {
    font-size: 1.5rem !important;
}

.icon-blocks:before,
.IconLink--blocks:before,
.SectionTabs-item-icon--blocks:before {
    content: "\e91f";
}

.icon-climate:before,
.SectionTabs-item-icon--climate:before,
.IconLink--climate:before {
    content: "\e920";
}

.icon-soil:before,
.IconLink--soil:before,
.SectionTabs-item-icon--soil:before {
    content: "\e915";
}

.icon-wetlands:before,
.SectionTabs-item-icon--wetlands:before,
.IconLink--wetlands:before {
    content: "\e912";
}

.icon-structures:before,
.SectionTabs-item-icon--structures:before,
.Nav-item--structures a:before,
.Nav-item--structures .a:before,
.IconLink--structures:before {
    content: "\e916";
}

.icon-crops:before,
.SectionTabs-item-icon--crops:before,
.IconLink--crops:before {
    content: "\e914";
}

.icon-animals:before,
.SectionTabs-item-icon--animals:before,
.IconLink--animals:before {
    content: "\e911";
}

.icon-supplements:before,
.SectionTabs-item-icon--supplements:before {
    content: "\e917";
}

.icon-fertiliser:before,
.IconLink--fertiliser:before,
.SectionTabs-item-icon--fertiliser:before {
    content: "\e923";
}

.icon-ic-ghg:before,
.SectionTabs-item-icon--ghg:before,
.IconLink--ghg:before {
    content: "\e913";
}

.icon-irrigation:before,
.SectionTabs-item-icon--irrigation:before,
.IconLink--irrigation:before {
    content: "\e925";
}

.icon-effluent:before,
.SectionTabs-item-icon--effluent:before {
    content: "\e922";
}

.icon-overview:before,
.SectionTabs-item-icon--overview:before,
.IconLink--overview:before {
    content: "\e91a";
}

.icon-irrigator:before,
.IconLink--irrigator:before {
    content: "\e92f";
}

.icon-sync:before,
.IconLink--sync:before {
    content: "\e929";
}

.icon-add-soil:before {
    content: "\e91e";
}

.icon-home:before,
.IconLink--home:before,
.Nav-item--home a:before {
    content: "\e90b";
}

.icon-profile:before,
.Nav-item--profile a:before {
    content: "\e919";
}

.icon-lock:before,
.IconLink--lock:before,
.RoundButton--lock:before {
    content: "\e918";
}

.icon-settings:before,
.IconLink--settings:before,
.Nav-item--settings a:before,
.RoundButton--settings:before {
    content: "\e930";
}

.icon-notification:before,
.IconLink--notification:before {
    content: "\e926";
}

.icon-arrow-left:before,
.IconLink--arrow-left:before,
.RoundButton--left:before {
    content: "\e903";
}

.icon-arrow-down:before,
.IconLink--arrow-down:before,
.RoundButton--down:before,
.icon-arrow-down-after:after,
.FilterRow-select:after,
.IconLink--arrow-down-after:after,
.RoundButton--down-after:after {
    content: "\e901";
}

.icon-arrow-filled:before,
.IconLink--arrow-filled:before {
    content: "\e902";
}

.icon-arrow-right:before,
.IconLink--arrow-right:before,
.RoundButton--right:before {
    content: "\e905";
}

.icon-arrow-up:before,
.IconLink--arrow-up:before,
.RoundButton--up:before,
.icon-arrow-up-after:after,
.IconLink--arrow-up-after:after,
.RoundButton--up-after:after {
    content: "\e906";
}

.icon-upload:before,
.IconLink--upload:before {
    content: "\e910";
}

.icon-download:before,
.IconLink--download:before {
    content: "\e924";
}

.icon-arrow-plus:before,
.IconLink--arrow-plus:before {
    content: "\e904";
}

.icon-cross:before,
.IconLink--cross:before {
    content: "\e908";
}

.icon-minus:before,
.IconLink--minus:before {
    content: "\e90d";
}

.icon-arrow-breadcrumb:before,
.IconLink--arrow-breadcrumb:before {
    content: "\e900";
}

.icon-dots-horizontal-triple:before,
.IconLink--dots-horizontal-triple:before {
    content: "\e939";
}

.icon-square:before {
    content: "\ea1e";
}

.icon-dot:before {
    content: "\e91c";
}

.icon-dollar:before,
.IconLink--dollar:before {
    content: "\e909";
}

.icon-search:before,
.IconLink--search:before,
.Nav-item--search a:before,
.Nav-item--search .a:before {
    content: "\e90e";
}

.icon-edit:before,
.IconLink--edit:before,
.RoundButton--edit:before {
    content: "\e90a";
}

.icon-copy:before,
.IconLink--copy:before,
.Nav-item--copy a:before {
    content: "\e91b";
}

.icon-alert:before,
.IconLink--alert:before {
    content: "\e90c";
}

.icon-info:before,
.IconLink--info:before,
.Nav-item--info a:before {
    content: "\e91d";
}

.icon-help:before,
.IconLink--help:before,
.Nav-item--help a:before {
    content: "\e921";
}

.icon-tick-circle:before,
.IconLink--tick-circle:before,
.dz-success-mark:before {
    content: "\e90f";
}

.icon-cross-circle:before,
.IconLink--cross-circle:before,
.dz-remove:before,
.dz-error-mark:before,
.RoundButton--cross-circle:before {
    content: "\e907";
}

.icon-stats-bars:before,
.Nav-item--stats-bars a:before,
.IconLink--stats-bars:before {
    content: "\e99c";
}

.icon-stats-dots:before,
.IconLink--stats-dots:before {
    content: "\e99b";
}

.icon-share:before,
.IconLink--share:before,
.RoundButton--share:before {
    content: "\ea82";
}

.icon-switch:before,
.IconLink--switch:before {
    content: "\e9b6";
}

.icon-chat:before,
.IconLink--chat:before {
    content: "\e96e";
}

.icon-bookmark:before,
.IconLink--bookmark:before {
    content: "\e9d2";
}

.icon-bookmarks:before,
.IconLink--bookmarks:before,
.RoundButton--bookmarks:before {
    content: "\e9d3";
}

.icon-history:before,
.IconLink--history:before {
    content: "\e94d";
}

.icon-clock:before,
.IconLink--clock:before {
    content: "\e94e";
}

.icon-equalizer:before,
.IconLink--equalizer:before,
.Nav-item--equalizer a:before {
    content: "\e992";
}

.icon-shield:before,
.IconLink--shield:before,
.Nav-item--shield a:before {
    content: "\e9b4";
}

.icon-info1:before,
.Nav-item--info1 a:before,
.IconLink--info1:before {
    content: "\ea0c";
}

.icon-compass:before,
.IconLink--compass:before {
    content: "\e94a";
}

.icon-wrench:before,
.IconLink--wrench:before {
    content: "\e991";
}

.icon-users:before,
.IconLink--users:before {
    content: "\e972";
}

.icon-question:before,
.IconLink--question:before {
    content: "\f128";
}

.icon-group:before,
.IconLink--group:before {
    content: "\ea58";
}

.icon-ungroup:before,
.IconLink--ungroup:before {
    content: "\ea59";
}

.icon-arrow-down-right:before,
.IconLink--arrow-down-right:before {
    content: "\ea35";
}

.icon-arrow-up-right:before,
.IconLink--arrow-up-right:before {
    content: "\ea33";
}

.icon-stack:before,
.IconLink--stack:before {
    content: "\e92e";
}

.icon-tag:before,
.IconLink--tag:before {
    content: "\e935";
}

.icon-tags:before,
.IconLink--tags:before {
    content: "\e936";
}

.icon-compare:before,
.IconLink--compare:before {
    content: "\e9b8";
}

.icon-replace:before,
.IconLink--replace:before {
    content: "\ea2d";
}

.icon-new-tab:before,
.IconLink--new-tab:before {
    content: "\ea7e";
}

.icon-floppy-disk:before,
.IconLink--floppy-disk:before {
    content: "\e962";
}

.icon-arrow-up1:before,
.IconLink--arrow-up1:before {
    content: "\ea32";
}

.icon-arrow-down1:before,
.IconLink--arrow-down1:before {
    content: "\ea36";
}

.icon-wand:before,
.IconLink--wand:before {
    content: "\e997";
}

.icon-hamburger:before,
.IconLink--hamburger:before,
.RoundButton--hamburger:before {
    content: "\e9bd";
}

.icon-beef:before,
.IconLink--beef:before {
    content: "\e91e";
}

.icon-dairy:before,
.IconLink--dairy:before,
.icon-dairyreplacements:before,
.IconLink--dairyreplacements:before {
    content: "\e927";
}

.icon-deer:before,
.IconLink--deer:before {
    content: "\e928";
}

.icon-dairygoat:before,
.IconLink--dairygoat:before {
    content: "\e92a";
}

.icon-outdoorpigs:before,
.IconLink--outdoorpigs:before {
    content: "\e92b";
}

.icon-sheep:before,
.IconLink--sheep:before {
    content: "\e92c";
}

.icon-creditcard:before,
.IconLink--creditcard:before {
    content: "\e93f";
}

.icon-animals1:before,
.IconLink--animals1:before {
    content: "\e92d";
}

.icon-animals2:before,
.IconLink--animals2:before {
    content: "\e931";
}

.icon-animals3:before,
.IconLink--animals3:before {
    content: "\e932";
}

.icon-trash:before,
.IconLink--trash:before {
    content: "\e933";
}

.icon-polygon:before,
.IconLink--polygon:before {
    content: "\e934";
}

.icon-placemarker:before,
.IconLink--placemarker:before {
    content: "\e938";
}

.icon-refresh:before,
.IconLink--refresh:before {
    content: "\e984";
}

.icon-move:before,
.IconLink--move:before {
    content: "\e937";
}

.FarmTableGroup-heading,
.Fieldset hr,
.Fieldset-heading,
.Modal-head,
.Tile-body .SubTabs {
    margin-left: -10px;
    width: calc(100% + 10px);
}

@media print, (min-width: 0.01px) {
    .FarmTableGroup-heading,
    .Fieldset hr,
    .Fieldset-heading,
    .Modal-head,
    .Tile-body .SubTabs {
        margin-left: -15px;
        width: calc(100% + 15px);
    }
}

@media (min-width: 1280px) {
    .FarmTableGroup-heading,
    .Fieldset hr,
    .Fieldset-heading,
    .Modal-head,
    .Tile-body .SubTabs {
        margin-left: -20px;
        width: calc(100% + 20px);
    }
}

.FarmTableGroup-heading,
.Fieldset hr,
.Fieldset-heading,
.Modal-head,
.Tile-body .SubTabs {
    margin-right: -10px;
    width: calc(100% + 10px);
}

@media print, (min-width: 0.01px) {
    .FarmTableGroup-heading,
    .Fieldset hr,
    .Fieldset-heading,
    .Modal-head,
    .Tile-body .SubTabs {
        margin-right: -15px;
        width: calc(100% + 15px);
    }
}

@media (min-width: 1280px) {
    .FarmTableGroup-heading,
    .Fieldset hr,
    .Fieldset-heading,
    .Modal-head,
    .Tile-body .SubTabs {
        margin-right: -20px;
        width: calc(100% + 20px);
    }
}

.FarmTableGroup-heading,
.Fieldset hr,
.Fieldset-heading,
.Modal-head,
.Tile-body .SubTabs {
    width: calc(100% + 20px);
}

@media print, (min-width: 0.01px) {
    .FarmTableGroup-heading,
    .Fieldset hr,
    .Fieldset-heading,
    .Modal-head,
    .Tile-body .SubTabs {
        width: calc(100% + 30px);
    }
}

@media (min-width: 1280px) {
    .FarmTableGroup-heading,
    .Fieldset hr,
    .Fieldset-heading,
    .Modal-head,
    .Tile-body .SubTabs {
        width: calc(100% + 40px);
    }
}

.flex-center,
.dropzone,
.Modal--loader,
.NCEIndicator div,
.NutrientsLost-indicator,
.NutrientSummary-value,
.Overlay,
.SubMenu-item a,
.SubTabs-item a,
.Tile-body-message,
.UtilityNav-user-token {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.Alert ul,
.NCEIndicator,
.PageHead-actions,
.PageHead-actions > *:not(:first-child) {
    margin-left: 10px;
}

@media print, (min-width: 0.01px) {
    .Alert ul,
    .NCEIndicator,
    .PageHead-actions,
    .PageHead-actions > *:not(:first-child) {
        margin-left: 15px;
    }
}

@media (min-width: 1280px) {
    .Alert ul,
    .NCEIndicator,
    .PageHead-actions,
    .PageHead-actions > *:not(:first-child) {
        margin-left: 20px;
    }
}

.PageHead .FarmTitle,
.PageHead-searchInput,
.PageHead-budgetOverview span {
    margin-right: 10px;
}

@media print, (min-width: 0.01px) {
    .PageHead .FarmTitle,
    .PageHead-searchInput,
    .PageHead-budgetOverview span {
        margin-right: 15px;
    }
}

@media (min-width: 1280px) {
    .PageHead .FarmTitle,
    .PageHead-searchInput,
    .PageHead-budgetOverview span {
        margin-right: 20px;
    }
}

.Alert,
.FarmTableGroup .FarmTable_wrapper + .FarmTable_wrapper,
.FarmTable_wrapper + .FarmTable_wrapper,
.Fieldset-heading + .Field,
.Fieldset-heading + .Field-group {
    margin-top: 10px;
}

@media print, (min-width: 0.01px) {
    .Alert,
    .FarmTableGroup .FarmTable_wrapper + .FarmTable_wrapper,
    .FarmTable_wrapper + .FarmTable_wrapper,
    .Fieldset-heading + .Field,
    .Fieldset-heading + .Field-group {
        margin-top: 15px;
    }
}

@media (min-width: 1280px) {
    .Alert,
    .FarmTableGroup .FarmTable_wrapper + .FarmTable_wrapper,
    .FarmTable_wrapper + .FarmTable_wrapper,
    .Fieldset-heading + .Field,
    .Fieldset-heading + .Field-group {
        margin-top: 20px;
    }
}

.Alert,
.ActionsBar--super,
.FarmTableGroup {
    margin-bottom: 10px;
}

@media print, (min-width: 0.01px) {
    .Alert,
    .ActionsBar--super,
    .FarmTableGroup {
        margin-bottom: 15px;
    }
}

@media (min-width: 1280px) {
    .Alert,
    .ActionsBar--super,
    .FarmTableGroup {
        margin-bottom: 20px;
    }
}

.BlockSelect,
.Fieldset,
.dropzone,
.Container,
.ActionsBar,
.BlockAreaTotals li,
.Button,
.CellGraph-labels,
.Collapsible-head,
.DescriptionOverview,
.FarmTableGroup-heading,
.FarmTable-supplementary-table-th,
.Fieldset-heading,
.Header-logo a,
[class*="Heading--"],
.Modal_wrapper,
.Modal2-wrapper,
.Modal,
.Modal-head,
.Nav-item a,
.Nav-item .a,
.Table th,
.Table td,
td.td-labelled .td-label,
.Tile-head,
.Tile-title,
.Tile-body,
.Tile-foot,
.NCEIndicator,
.PageHead-searchInput {
    padding-left: 10px;
}

@media print, (min-width: 0.01px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .Container,
    .ActionsBar,
    .BlockAreaTotals li,
    .Button,
    .CellGraph-labels,
    .Collapsible-head,
    .DescriptionOverview,
    .FarmTableGroup-heading,
    .FarmTable-supplementary-table-th,
    .Fieldset-heading,
    .Header-logo a,
    [class*="Heading--"],
    .Modal_wrapper,
    .Modal2-wrapper,
    .Modal,
    .Modal-head,
    .Nav-item a,
    .Nav-item .a,
    .Table th,
    .Table td,
    td.td-labelled .td-label,
    .Tile-head,
    .Tile-title,
    .Tile-body,
    .Tile-foot,
    .NCEIndicator,
    .PageHead-searchInput {
        padding-left: 15px;
    }
}

@media (min-width: 1280px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .Container,
    .ActionsBar,
    .BlockAreaTotals li,
    .Button,
    .CellGraph-labels,
    .Collapsible-head,
    .DescriptionOverview,
    .FarmTableGroup-heading,
    .FarmTable-supplementary-table-th,
    .Fieldset-heading,
    .Header-logo a,
    [class*="Heading--"],
    .Modal_wrapper,
    .Modal2-wrapper,
    .Modal,
    .Modal-head,
    .Nav-item a,
    .Nav-item .a,
    .Table th,
    .Table td,
    td.td-labelled .td-label,
    .Tile-head,
    .Tile-title,
    .Tile-body,
    .Tile-foot,
    .NCEIndicator,
    .PageHead-searchInput {
        padding-left: 20px;
    }
}

.BlockSelect,
.Fieldset,
.dropzone,
.Container,
.ActionsBar,
.BlockAreaTotals li,
.Button,
.CellGraph-labels,
.Collapsible-head,
.DescriptionOverview,
.FarmTableGroup-heading,
.FarmTable-supplementary-table-th,
.Fieldset-heading,
.Header-logo a,
[class*="Heading--"],
.Modal_wrapper,
.Modal2-wrapper,
.Modal,
.Modal-head,
.Nav-item a,
.Nav-item .a,
.Table th,
.Table td,
td.td-labelled .td-label,
.Tile-head,
.Tile-title,
.Tile-body,
.Tile-foot,
.PageHead-budgetOverview span {
    padding-right: 10px;
}

@media print, (min-width: 0.01px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .Container,
    .ActionsBar,
    .BlockAreaTotals li,
    .Button,
    .CellGraph-labels,
    .Collapsible-head,
    .DescriptionOverview,
    .FarmTableGroup-heading,
    .FarmTable-supplementary-table-th,
    .Fieldset-heading,
    .Header-logo a,
    [class*="Heading--"],
    .Modal_wrapper,
    .Modal2-wrapper,
    .Modal,
    .Modal-head,
    .Nav-item a,
    .Nav-item .a,
    .Table th,
    .Table td,
    td.td-labelled .td-label,
    .Tile-head,
    .Tile-title,
    .Tile-body,
    .Tile-foot,
    .PageHead-budgetOverview span {
        padding-right: 15px;
    }
}

@media (min-width: 1280px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .Container,
    .ActionsBar,
    .BlockAreaTotals li,
    .Button,
    .CellGraph-labels,
    .Collapsible-head,
    .DescriptionOverview,
    .FarmTableGroup-heading,
    .FarmTable-supplementary-table-th,
    .Fieldset-heading,
    .Header-logo a,
    [class*="Heading--"],
    .Modal_wrapper,
    .Modal2-wrapper,
    .Modal,
    .Modal-head,
    .Nav-item a,
    .Nav-item .a,
    .Table th,
    .Table td,
    td.td-labelled .td-label,
    .Tile-head,
    .Tile-title,
    .Tile-body,
    .Tile-foot,
    .PageHead-budgetOverview span {
        padding-right: 20px;
    }
}

.BlockSelect,
.Fieldset,
.dropzone,
.PageBody,
.Modal_wrapper,
.Modal2-wrapper,
.SubTabs-item a,
.Tile-body {
    padding-top: 10px;
}

@media print, (min-width: 0.01px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .PageBody,
    .Modal_wrapper,
    .Modal2-wrapper,
    .SubTabs-item a,
    .Tile-body {
        padding-top: 15px;
    }
}

@media (min-width: 1280px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .PageBody,
    .Modal_wrapper,
    .Modal2-wrapper,
    .SubTabs-item a,
    .Tile-body {
        padding-top: 12px;
    }
}

.BlockSelect,
.Fieldset,
.dropzone,
.PageBody,
.Modal_wrapper,
.Modal2-wrapper,
.SubTabs-item a,
.Tile-body {
    padding-bottom: 10px;
}

@media print, (min-width: 0.01px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .PageBody,
    .Modal_wrapper,
    .Modal2-wrapper,
    .SubTabs-item a,
    .Tile-body {
        padding-bottom: 12px;
    }
}

@media (min-width: 1280px) {
    .BlockSelect,
    .Fieldset,
    .dropzone,
    .PageBody,
    .Modal_wrapper,
    .Modal2-wrapper,
    .SubTabs-item a,
    .Tile-body {
        padding-bottom: 12px;
    }
}

.FarmTable-supplementary,
.NutrientsLost-n,
.NutrientsLost-p,
.NutrientsLost-ghg {
    padding-left: 10px;
}

.FarmTable-supplementary {
    padding-right: 10px;
}

.FarmTableGroup-heading,
.Modal-head,
.Tile-head {
    padding-top: 10px;
}

.FarmTableGroup-heading,
.Modal-head,
.Tile-head {
    padding-bottom: 10px;
}

@media print, (min-width: 0.01px) {
    .u-split,
    .ActionsBar,
    .FarmTableGroup-heading {
        display: -webkit-box;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

        .u-split > :not(:first-child):last-child,
        .ActionsBar > :not(:first-child):last-child,
        .FarmTableGroup-heading > :not(:first-child):last-child {
            margin-left: auto;
        }
}

.stretch,
.DirectoryList-items ul,
.Modal_wrapper,
.Modal2-wrapper,
.Overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.Container {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1600px) {
    .Container {
        max-width: 114.28571rem;
    }
}

.Grid {
    display: block;
    font-size: 0;
    margin: 0;
    padding: 0;
    text-align: left;
}

.Grid--withGutter {
    margin-left: calc(-0.5 * 20px);
    margin-right: calc(-0.5 * 20px);
}

@media print, (min-width: 0.01px) {
    .Grid--withGutter {
        margin-left: calc(-0.5 * 30px);
        margin-right: calc(-0.5 * 30px);
    }
}

@media (min-width: 1280px) {
    .Grid--withGutter {
        margin-left: calc(-0.5 * 40px);
        margin-right: calc(-0.5 * 40px);
    }
}

.Grid--withGutter > .Grid-cell {
    padding-left: calc(0.5 * 20px);
    padding-right: calc(0.5 * 20px);
}

@media print, (min-width: 0.01px) {
    .Grid--withGutter > .Grid-cell {
        padding-left: calc(0.5 * 30px);
        padding-right: calc(0.5 * 30px);
    }
}

@media (min-width: 1280px) {
    .Grid--withGutter > .Grid-cell {
        padding-left: calc(0.5 * 40px);
        padding-right: calc(0.5 * 40px);
    }
}

.Grid--withSmallGutter > .Grid-cell {
    padding-left: calc(0.5 * 20px);
    padding-right: calc(0.5 * 20px);
}

.Grid-cell {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: top;
    width: 100%;
}

.Grid-cell--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

html {
    height: 100%;
}

body {
    background-color: #d8deda;
    min-width: 0.01px;
}

.react-page {
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.Page {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
}

.ActionsBar {
    background-color: #f6f6f6;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: #e5e9e7;
    min-height: 36px;
}

@media (max-width: 0px) {
    .ActionsBar {
        padding-top: 7.5px;
        padding-bottom: 7.5px;
    }
}

@media print, (min-width: 0.01px) {
    .ActionsBar {
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.FarmTable_wrapper .ActionsBar {
    border-width: 1px 0 0 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #d8deda;
    border-bottom: 1px solid #d8deda;
}

.ActionsBar--super {
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075);
}

.ActionsBar-left,
.ActionsBar-right {
    margin-top: 5px;
    margin-bottom: 5px;
}

@media print, (min-width: 0.01px) {
    .ActionsBar-left,
    .ActionsBar-right {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }
}

@media print, (min-width: 0.01px) {
    .ActionsBar-right {
        margin-left: auto;
    }
}

.ActionsBar-left {
    margin-right: 15px;
}

@media print, (min-width: 0.01px) {
    .ActionsBar-left {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
    }
}

.ActionsBar-status {
    white-space: nowrap;
}

@media print, (min-width: 0.01px) {
    .ActionsBar-status {
        margin-right: 15px;
    }
}

@media print, (min-width: 0.01px) {
    .ActionsBar-links {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
    }

        .ActionsBar-links li:not(:last-child) {
            margin-right: 15px;
        }
}

.Alert_label {
    margin-top: 22.5px;
}

.Alert {
    padding: 15px 15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

    .Alert .icon {
        margin-right: 10px;
        flex-shrink: 0;
        align-self: flex-start;
    }

    .Alert ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
    }

.Alert--error {
    background-color: #f8edec;
}

    .Alert--error .icon {
        color: #973b36;
    }

.Alert--help {
    background-color: #ecf8f1;
}

    .Alert--help .icon {
        color: #36975c;
    }

.Alert--info {
    background-color: #ecf4f8;
}

    .Alert--info .icon {
        color: #509bc2;
    }

.Alert--success {
    background-color: #ecf8f1;
}

    .Alert--success .icon {
        color: #36975c;
    }

    .Alert--success.Alert--highlight {
        border: 2px solid #36975c;
        border-radius: 5px;
    }

.Alert--warning {
    background-color: #fbf1e9;
}

    .Alert--warning .icon {
        color: #e39153;
    }

    .Alert--warning.Alert--highlight {
        border: 2px solid #e39153;
        border-radius: 5px;
    }

.Alert_label + .Alert {
    margin-top: 0;
}

.Alert > p:first-child,
.Alert > div > p:first-child,
.Alert > div > div > p:first-child {
    margin-top: 0;
}

.AnnualCheckboxes {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    margin-top: 7.5px;
}

@media (max-width: 0px) {
    .AnnualCheckboxes {
        overflow-x: scroll;
    }
}

.AnnualCheckboxes .Checkbox {
    display: -webkit-inline-box;
    display: inline-flex;
}

    .AnnualCheckboxes .Checkbox:not(:last-child) {
        margin-right: 30px;
        margin-bottom: 0;
    }

.AnnualCheckboxes .Checkbox-label {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    align-items: center;
}

    .AnnualCheckboxes .Checkbox-label::before {
        margin-right: 0;
        margin-top: 7.5px;
    }

.BlockAreaTotals {
    min-height: 45px;
    background-color: #f8f9f8;
}

@media print, (min-width: 0.01px) {
    .BlockAreaTotals {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
    }
}

.BlockAreaTotals li {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.BlockList {
    margin-top: -7.5px;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
}

.BlockList-item {
    cursor: default;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 500;
    color: #8d9590;
    border: 2px dotted #8d9590;
    padding: 5px 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-shrink: 0;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-radius: 2px;
}

@media (max-width: 0px) {
    .BlockList-item:nth-child(odd) {
        margin-right: 5px;
    }
}

@media print, (min-width: 0.01px) {
    .BlockList-item:not(:last-child) {
        margin-right: 5px;
    }
}

.BlockList-item.is-active {
    border-color: transparent;
    color: #ffffff;
    background: repeating-linear-gradient(-60deg, #4591b9, #4591b9 5px, #549bc0 5px, #549bc0 10px);
}

.BlockList-item-close {
    display: none;
    margin-right: -2px;
    margin-left: 10px;
    color: #ffffff;
    padding: 4px 2px;
}

    .BlockList-item-close .icon {
        font-size: 1rem;
        font-weight: 700;
    }

.BlockList-item.is-active .BlockList-item-close {
    display: -webkit-box;
    display: flex;
}

.BlockList-item-close:hover {
    background-color: #fff;
    border-radius: 2px;
    color: #509bc2;
}

.BlockList-item--soil {
    text-transform: none;
    background-color: #e8f7ff;
    border-color: #509bc2;
    color: #58585a;
    font-weight: 400;
}

.BlockSelect {
    margin-top: 15px;
    border: 2px dotted #bec8c2;
}

    .BlockSelect .Field {
        margin-top: 0;
    }

    .BlockSelect .BlockList {
        margin-top: 10px;
        margin-bottom: 10px;
        border-top: 1px dashed #bec8c2;
        background-color: transparent;
        padding-top: 5px;
    }

.ButtonBar {
    margin-top: 30px;
    padding-top: 7.5px;
    padding-bottom: 30px;
    border-top: 1px solid #e5e9e7;
}

.ButtonBar--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e5e9e7;
    z-index: 15;
    padding: 8px 20px !important;
}

@media print, (min-width: 529px) {
    .ButtonBar--fixed {
        display: -webkit-box;
        display: flex;
    }
}

@media (max-width: 0px) {
    .ButtonBar .Button {
        margin-top: 15px;
        width: 100%;
    }
}

@media print, (min-width: 529px) {
    .ButtonBar {
        padding-top: 30px;
        padding-bottom: 45px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

        .ButtonBar .Button + .Button {
            margin-left: 30px;
        }
}

.Modal-footer .ButtonBar {
    padding-bottom: 0;
}

.ButtonBar-right {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

@media (max-width: 0px) {
    .ButtonBar-right {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media (max-width: 529px) {
    .ButtonBar-right {
        display: block;
    }

    .ButtonBar-left .Button {
        width: 100%;
    }

    .ButtonBar-right .Button {
        margin-left: 0 !important;
        margin-top: 10px;
        display: block;
        width: 100%;
    }
}

.Button {
    font-size: 1.14286rem;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 44px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-decoration: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: inherit;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 5px;
}

@media print, (min-width: 0.01px) {
    .Button {
        min-width: 140px;
    }
}

.Button--block {
    width: 100%;
}

.Button--lg {
    min-width: 100%;
    max-width: 100%;
}

@media print, (min-width: 0.01px) {
    .Button--lg {
        min-width: 320px;
    }
}

.Button-fill{
    width: 100%;
}

.Button--sm {
    font-weight: 600;
    font-size: 0.9rem;
    height: 34px;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 20px;
    min-width: auto;
}

.Button:active,
.Button:focus-visible {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

.Button:disabled,
.Button.is-disabled,
.waiting button[type="submit"].Button,
.offline button[type="submit"].Button {
    pointer-events: none;
}

.Button {
    background-color: #509bc2;
    color: #ffffff;
}

    .Button:hover,
    .Button:active,
    .Button:focus-visible,
    .Button:disabled {
        color: #ffffff;
    }

    .Button:hover {
        background-color: rgba(80, 155, 194, 0.7);
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 2px #5386a0;
    }

    .Button:active,
    .Button:focus-visible {
        background-color: #5386a0;
    }

    .Button:disabled,
    .Button.is-disabled {
        background-color: rgba(80, 155, 194, 0.3);
    }

.Button--primary {
    background-color: #44aa6c;
    color: #fff;
}

    .Button--primary:hover,
    .Button--primary:active,
    .Button--primary:focus-visible,
    .Button--primary:disabled,
    .offline button[type="submit"].Button--primary {
        color: #ffffff;
    }

    .Button--primary:hover {
        background-color: rgba(68, 170, 108, 0.7);
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 2px #309658;
    }

    .Button--primary:active,
    .Button--primary:focus-visible {
        background-color: #309658;
    }

    .Button--primary:disabled,
    .Button--primary.is-disabled,
    .offline button[type="submit"].Button--primary {
        background-color: rgba(68, 170, 108, 0.3);
    }

.Button--sm {
    background-color: #ffffff;
    color: #509bc2;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 1px rgba(0, 0, 0, 0.175);
}

.Button--secondary {
    background-color: var(--white);
    color: var(--blue-60);
    border: 2px solid var(--blue-60);
}

.Button--secondary-grey {
    background-color: var(--white);
    color: var(--slate-grey-30);
    border: 2px solid var(--slate-grey-30);
}


.Button--sm:hover,
.Button--sm:active,
.Button--sm:focus-visible,
.Button--sm:disabled {
    color: #509bc2;
}

.Button--secondary:focus-visible {
    outline: 2px solid var(--focus);
    outline-offset: 2px;
    background-color: var(--white);
    color: var(--blue-60);
    border: 2px solid var(--blue-60);
}


.offline button[type="submit"].Button--secondary,
.Button--secondary:hover {
    color: var(--blue-90);
    background-color: var(--blue-10);
    border: 2px solid var(--blue-90);
    box-shadow: none;
}

.Button--secondary-grey:hover {
    color: var(--blue-90);
    background-color: var(--white);
    border: 2px solid var(--slate-grey-60);
    box-shadow: none;
}

.Button--sm:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 2px #5386a0;
    background-color: #ffffff;
}

.Button--secondary:active {
    border: 2px solid var(--blue-10);
    background-color: var(--blue-10);
    color: var(--blue-90);
    outline: none;
}

.Button--sm:active,
.Button--sm:focus-visible {
    background-color: #e5f0f6;
}

.Button--secondary:disabled,
.Button--secondary.is-disabled,
.offline button[type="submit"].Button--secondary {
    border: 2px solid var(--neutral-50);
    background-color: var(--white);
    color: var(--neutral-50);
}

.Button--sm:disabled,
.is-disabled.Button--sm {
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075);
}

.Button--tertiary {
    background-color: #ca5e0c;
    color: #fff;
}

    .Button--tertiary:hover {
        background-color: rgba(202, 94, 12, 0.7);
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 2px #a45b2b;
        color: #fff;
    }

    .Button--tertiary:active,
    .Button--tertiary:focus-visible {
        background-color: #a45b2b;
        color: #fff;
    }

    .Button--tertiary:disabled,
    .Button--tertiary.is-disabled,
    .offline button[type="submit"].Button--tertiary {
        background-color: rgba(202, 94, 12, 0.3);
    }

.Button--alert {
    color: #973b36;
    background-color: var(--white);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 1px #973b36;
}

    .Button--alert:hover {
        background-color: #f8edec;
        color: #973b36;
        box-shadow: inset 0 0 0 1px #973b36;
    }

    .Button--alert:active {
        background-color: #973b36;
        color: white;
    }

    .Button--alert:focus-visible {
        outline: 2px solid var(--focus);
        outline-offset: 2px;
        background-color: var(--white);
        color: #973b36;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 1px #973b36;
    }

.Calendar-title {
    min-width: 190px;
}

@media (min-width: 1280px) {
    .Calendar-title {
        min-width: 254px;
    }
}

.Calendar-month {
    min-width: 45px;
}

    .Calendar-month.Calendar-month {
        text-align: center;
    }

.Calendar-slot {
    text-align: center;
    min-height: 45px;
    background-clip: padding-box;
}

    .Calendar-slot.Calendar-slot {
        padding: 4px 4px;
    }

.Calendar-icon {
    position: relative;
    z-index: 10;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 30px;
    max-height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.Calendar-icon-text {
    font-size: 0.85rem;
    color: #8d9590;
    position: relative;
    top: -5px;
}

.Calendar-slot-menu-trigger {
    display: none;
    width: 24px;
    height: 24px;
    padding-top: 4px;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-box-align: start;
    align-items: start;
    -webkit-box-pack: center;
    justify-content: center;
}

.Calendar-slot:hover .Calendar-slot-menu-trigger {
    display: -webkit-box;
    display: flex;
}

.Calendar-slot-menu {
    z-index: 20;
    display: block;
    position: absolute;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #bec8c2;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075);
    border-radius: 4px;
}

    .Calendar-slot-menu li {
        white-space: nowrap;
    }

        .Calendar-slot-menu li:not(:first-child) a::before {
            content: "";
            position: absolute;
            width: calc(100% - 30px);
            left: 15px;
            top: 0;
            height: 1px;
            background-color: #bec8c2;
        }

    .Calendar-slot-menu .a,
    .Calendar-slot-menu a {
        padding: 7.5px 15px;
        position: relative;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

        .Calendar-slot-menu .a img,
        .Calendar-slot-menu a img {
            margin-right: 7.5px;
        }

.CellGraph-labels {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 0;
    padding-top: 9px;
    padding-bottom: 18px;
    text-align: right;
    height: 134px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    list-style: none;
}

.CellGraph {
    height: 134px;
    position: relative;
    padding-top: 18px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    align-items: flex-end;
}

.CellGraph-ticks {
    padding-top: 18px;
    padding-bottom: 27px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

    .CellGraph-ticks i {
        display: block;
        height: 0px;
        border-top: 1px dashed #bec8c2;
    }

.CellGraph-fill {
    position: relative;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
}

    .CellGraph-fill::after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
    }

.CellGraph-legend {
    display: inline-block;
    padding-left: 24px;
    position: relative;
    height: 20px;
}

    .CellGraph-legend:not(:last-child) {
        margin-right: 30px;
    }

    .CellGraph-legend::before {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        top: 1px;
        left: 1px;
    }

.CellGraph-legend--block1::before,
.CellGraph--multi .CellGraph-fill i,
.CellGraph-legend--green::before {
    background-color: #31b075;
}

    .CellGraph-legend--block2::before,
    .CellGraph--multi .CellGraph-fill i + i + i,
    .CellGraph-legend--lime::before {
        background-color: #a1cc70;
    }

.CellGraph-legend--block3::before {
    background-color: #9dddc4;
}

.CellGraph-legend--block4::before {
    background-color: #1a4e83;
}

.CellGraph-legend--block5::before {
    background-color: #4781bc;
}

.CellGraph-legend--block6::before {
    background-color: #7ab0e5;
}

.CellGraph-legend--block7::before {
    background-color: #227f54;
}

.CellGraph-legend--block8::before {
    background-color: #105035;
}

.CellGraph-legend--block9::before {
    background-color: #042417;
}

.CellGraph-legend--block10::before,
.CellGraph--multi .CellGraph-fill i + i,
.CellGraph-legend--orange::before {
    background-color: #db7e49;
}

.CellGraph-legend--block11::before {
    background-color: #e29d74;
}

.CellGraph-legend--block12::before {
    background-color: #fbe2b7;
}

.CellGraph-legend--block13::before {
    background-color: #22879b;
}

.CellGraph-legend--block14::before {
    background-color: #39abc0;
}

.CellGraph-legend--block15::before {
    background-color: #6ee0f3;
}

.CellGraph-legend--block16::before {
    background-color: #35a7a8;
}

.CellGraph-legend--block17::before {
    background-color: #59d8d9;
}

.CellGraph-legend--block18::before {
    background-color: #a3dcdc;
}

.CellGraph--block1 .CellGraph-fill::after {
    background-color: #31b075;
}

.CellGraph--block2 .CellGraph-fill::after {
    background-color: #a1cc70;
}

.CellGraph--block3 .CellGraph-fill::after {
    background-color: #9dddc4;
}

.CellGraph--block4 .CellGraph-fill::after {
    background-color: #1a4e83;
}

.CellGraph--block5 .CellGraph-fill::after {
    background-color: #4781bc;
}

.CellGraph--block6 .CellGraph-fill::after {
    background-color: #7ab0e5;
}

.CellGraph--block7 .CellGraph-fill::after {
    background-color: #227f54;
}

.CellGraph--block8 .CellGraph-fill::after {
    background-color: #105035;
}

.CellGraph--block9 .CellGraph-fill::after {
    background-color: #042417;
}

.CellGraph--block10 .CellGraph-fill::after {
    background-color: #db7e49;
}

.CellGraph--block11 .CellGraph-fill::after {
    background-color: #e29d74;
}

.CellGraph--block12 .CellGraph-fill::after {
    background-color: #fbe2b7;
}

.CellGraph--block13 .CellGraph-fill::after {
    background-color: #22879b;
}

.CellGraph--block14 .CellGraph-fill::after {
    background-color: #39abc0;
}

.CellGraph--block15 .CellGraph-fill::after {
    background-color: #6ee0f3;
}

.CellGraph--block16 .CellGraph-fill::after {
    background-color: #35a7a8;
}

.CellGraph--block17 .CellGraph-fill::after {
    background-color: #59d8d9;
}

.CellGraph--block18 .CellGraph-fill::after {
    background-color: #a3dcdc;
}

.CellGraph--multi .CellGraph-fill {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

    .CellGraph--multi .CellGraph-fill i {
        display: block;
        width: 100%;
    }

    .CellGraph--multi .CellGraph-fill::after {
        display: none;
    }

.Collapsible-head {
    font-size: 1rem;
    color: #ffffff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #364052;
    min-height: 40px;
    border-radius: 5px;
    cursor: pointer;
}

    .Collapsible-head.is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

.Collapsible-head--secondary {
    background-color: #509bc2;
}

.Collapsible-head-affordance {
    margin-left: auto;
    text-decoration: none;
    color: #ffffff;
    transition: -webkit-transform 0.2s linear;
    transition: transform 0.2s linear;
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}

    .Collapsible-head-affordance.is-active,
    .Collapsible-head-affordance:hover,
    .Collapsible-head-affordance:active,
    .Collapsible-head-affordance:focus {
        color: #ffffff;
    }

.Collapsible-head.is-open .Collapsible-head-affordance {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.Collapsible-body {
    transition: all 0.2s linear;
}

    .Collapsible-body.is-open {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

.Collapsible-body--padded {
    border: 0 solid #ffffff;
    padding-left: 7.5px;
    padding-right: 7.5px;
}

    .Collapsible-body--padded.is-open {
        border: 1px solid #eeeeee;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
    }

@media print, (min-width: 0.01px) {
    .DataWidthTable {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
    }
}

[data-width~="grow"] {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
}

[data-width~="shrink"] {
    flex-shrink: 1 !important;
}

[data-width] {
    flex-basis: auto !important;
}

[data-width~="2.5"] {
    width: 2.5% !important;
}

[data-width~="5"] {
    width: 5% !important;
}

[data-width~="7.5"] {
    width: 7.5% !important;
}

[data-width~="10"] {
    width: 10% !important;
}

[data-width~="12.5"] {
    width: 12.5% !important;
}

[data-width~="15"] {
    width: 15% !important;
}

[data-width~="16.7"] {
    width: 16.66% !important;
}

[data-width~="17.5"] {
    width: 17.5% !important;
}

[data-width~="20"] {
    width: 20% !important;
}

[data-width~="22.5"] {
    width: 22.5% !important;
}

[data-width~="25"] {
    width: 25% !important;
}

[data-width~="27.5"] {
    width: 27.5% !important;
}

[data-width~="30"] {
    width: 30% !important;
}

[data-width~="32.5"] {
    width: 32.5% !important;
}

[data-width~="35"] {
    width: 35% !important;
}

[data-width~="37.5"] {
    width: 37.5% !important;
}

[data-width~="40"] {
    width: 40% !important;
}

[data-width~="42.5"] {
    width: 42.5% !important;
}

[data-width~="45"] {
    width: 45% !important;
}

[data-width~="47.5"] {
    width: 47.5% !important;
}

[data-width~="50"] {
    width: 50% !important;
}

[data-width~="52.5"] {
    width: 52.5% !important;
}

[data-width~="55"] {
    width: 55% !important;
}

[data-width~="57.5"] {
    width: 57.5% !important;
}

[data-width~="60"] {
    width: 60% !important;
}

[data-width~="62.5"] {
    width: 62.5% !important;
}

[data-width~="65"] {
    width: 65% !important;
}

[data-width~="67.5"] {
    width: 67.5% !important;
}

[data-width~="70"] {
    width: 70% !important;
}

[data-width~="72.5"] {
    width: 72.5% !important;
}

[data-width~="75"] {
    width: 75% !important;
}

[data-width~="77.5"] {
    width: 77.5% !important;
}

[data-width~="80"] {
    width: 80% !important;
}

[data-width~="82.5"] {
    width: 82.5% !important;
}

[data-width~="85"] {
    width: 85% !important;
}

[data-width~="87.5"] {
    width: 87.5% !important;
}

[data-width~="90"] {
    width: 90% !important;
}

[data-width~="92.5"] {
    width: 92.5% !important;
}

[data-width~="95"] {
    width: 95% !important;
}

[data-width~="97.5"] {
    width: 97.5% !important;
}

[data-width~="100"] {
    width: 100% !important;
}

[data-width~="33"] {
    width: 33.33333% !important;
}

@media (min-width: 0.01px) and (max-width: 1279.99px) {
    [data-width~="md-grow"] {
        -webkit-box-flex: 1 !important;
        flex-grow: 1 !important;
    }

    [data-width~="md-shrink"] {
        flex-shrink: 1 !important;
    }

    [data-width] {
        flex-basis: auto !important;
    }

    [data-width~="md-2.5"] {
        width: 2.5% !important;
    }

    [data-width~="md-5"] {
        width: 5% !important;
    }

    [data-width~="md-7.5"] {
        width: 7.5% !important;
    }

    [data-width~="md-10"] {
        width: 10% !important;
    }

    [data-width~="md-12.5"] {
        width: 12.5% !important;
    }

    [data-width~="md-15"] {
        width: 15% !important;
    }

    [data-width~="md-17.5"] {
        width: 17.5% !important;
    }

    [data-width~="md-20"] {
        width: 20% !important;
    }

    [data-width~="md-22.5"] {
        width: 22.5% !important;
    }

    [data-width~="md-25"] {
        width: 25% !important;
    }

    [data-width~="md-27.5"] {
        width: 27.5% !important;
    }

    [data-width~="md-30"] {
        width: 30% !important;
    }

    [data-width~="md-32.5"] {
        width: 32.5% !important;
    }

    [data-width~="md-35"] {
        width: 35% !important;
    }

    [data-width~="md-37.5"] {
        width: 37.5% !important;
    }

    [data-width~="md-40"] {
        width: 40% !important;
    }

    [data-width~="md-42.5"] {
        width: 42.5% !important;
    }

    [data-width~="md-45"] {
        width: 45% !important;
    }

    [data-width~="md-47.5"] {
        width: 47.5% !important;
    }

    [data-width~="md-50"] {
        width: 50% !important;
    }

    [data-width~="md-52.5"] {
        width: 52.5% !important;
    }

    [data-width~="md-55"] {
        width: 55% !important;
    }

    [data-width~="md-57.5"] {
        width: 57.5% !important;
    }

    [data-width~="md-60"] {
        width: 60% !important;
    }

    [data-width~="md-62.5"] {
        width: 62.5% !important;
    }

    [data-width~="md-65"] {
        width: 65% !important;
    }

    [data-width~="md-67.5"] {
        width: 67.5% !important;
    }

    [data-width~="md-70"] {
        width: 70% !important;
    }

    [data-width~="md-72.5"] {
        width: 72.5% !important;
    }

    [data-width~="md-75"] {
        width: 75% !important;
    }

    [data-width~="md-77.5"] {
        width: 77.5% !important;
    }

    [data-width~="md-80"] {
        width: 80% !important;
    }

    [data-width~="md-82.5"] {
        width: 82.5% !important;
    }

    [data-width~="md-85"] {
        width: 85% !important;
    }

    [data-width~="md-87.5"] {
        width: 87.5% !important;
    }

    [data-width~="md-90"] {
        width: 90% !important;
    }

    [data-width~="md-92.5"] {
        width: 92.5% !important;
    }

    [data-width~="md-95"] {
        width: 95% !important;
    }

    [data-width~="md-97.5"] {
        width: 97.5% !important;
    }

    [data-width~="md-100"] {
        width: 100% !important;
    }

    [data-width~="md-33"] {
        width: 33.33333% !important;
    }
}

@media (min-width: 1280px) {
    [data-width~="xl-grow"] {
        -webkit-box-flex: 1 !important;
        flex-grow: 1 !important;
    }

    [data-width~="xl-shrink"] {
        flex-shrink: 1 !important;
    }

    [data-width] {
        flex-basis: auto !important;
    }

    [data-width~="xl-2.5"] {
        width: 2.5% !important;
    }

    [data-width~="xl-5"] {
        width: 5% !important;
    }

    [data-width~="xl-7.5"] {
        width: 7.5% !important;
    }

    [data-width~="xl-10"] {
        width: 10% !important;
    }

    [data-width~="xl-12.5"] {
        width: 12.5% !important;
    }

    [data-width~="xl-15"] {
        width: 15% !important;
    }

    [data-width~="xl-17.5"] {
        width: 17.5% !important;
    }

    [data-width~="xl-20"] {
        width: 20% !important;
    }

    [data-width~="xl-22.5"] {
        width: 22.5% !important;
    }

    [data-width~="xl-25"] {
        width: 25% !important;
    }

    [data-width~="xl-27.5"] {
        width: 27.5% !important;
    }

    [data-width~="xl-30"] {
        width: 30% !important;
    }

    [data-width~="xl-32.5"] {
        width: 32.5% !important;
    }

    [data-width~="xl-35"] {
        width: 35% !important;
    }

    [data-width~="xl-37.5"] {
        width: 37.5% !important;
    }

    [data-width~="xl-40"] {
        width: 40% !important;
    }

    [data-width~="xl-42.5"] {
        width: 42.5% !important;
    }

    [data-width~="xl-45"] {
        width: 45% !important;
    }

    [data-width~="xl-47.5"] {
        width: 47.5% !important;
    }

    [data-width~="xl-50"] {
        width: 50% !important;
    }

    [data-width~="xl-52.5"] {
        width: 52.5% !important;
    }

    [data-width~="xl-55"] {
        width: 55% !important;
    }

    [data-width~="xl-57.5"] {
        width: 57.5% !important;
    }

    [data-width~="xl-60"] {
        width: 60% !important;
    }

    [data-width~="xl-62.5"] {
        width: 62.5% !important;
    }

    [data-width~="xl-65"] {
        width: 65% !important;
    }

    [data-width~="xl-67.5"] {
        width: 67.5% !important;
    }

    [data-width~="xl-70"] {
        width: 70% !important;
    }

    [data-width~="xl-72.5"] {
        width: 72.5% !important;
    }

    [data-width~="xl-75"] {
        width: 75% !important;
    }

    [data-width~="xl-77.5"] {
        width: 77.5% !important;
    }

    [data-width~="xl-80"] {
        width: 80% !important;
    }

    [data-width~="xl-82.5"] {
        width: 82.5% !important;
    }

    [data-width~="xl-85"] {
        width: 85% !important;
    }

    [data-width~="xl-87.5"] {
        width: 87.5% !important;
    }

    [data-width~="xl-90"] {
        width: 90% !important;
    }

    [data-width~="xl-92.5"] {
        width: 92.5% !important;
    }

    [data-width~="xl-95"] {
        width: 95% !important;
    }

    [data-width~="xl-97.5"] {
        width: 97.5% !important;
    }

    [data-width~="xl-100"] {
        width: 100% !important;
    }

    [data-width~="xl-33"] {
        width: 33.33333% !important;
    }
}


@media (min-width: 1450px) {
    [data-width~="xxl-grow"] {
        -webkit-box-flex: 1 !important;
        flex-grow: 1 !important;
    }

    [data-width~="xxl-shrink"] {
        flex-shrink: 1 !important;
    }

    [data-width] {
        flex-basis: auto !important;
    }

    [data-width~="xxl-2.5"] {
        width: 2.5% !important;
    }

    [data-width~="xxl-5"] {
        width: 5% !important;
    }

    [data-width~="xxl-7.5"] {
        width: 7.5% !important;
    }

    [data-width~="xxl-10"] {
        width: 10% !important;
    }

    [data-width~="xxl-12.5"] {
        width: 12.5% !important;
    }

    [data-width~="xxl-15"] {
        width: 15% !important;
    }

    [data-width~="xxl-17.5"] {
        width: 17.5% !important;
    }

    [data-width~="xxl-20"] {
        width: 20% !important;
    }

    [data-width~="xxl-22.5"] {
        width: 22.5% !important;
    }

    [data-width~="xxl-25"] {
        width: 25% !important;
    }

    [data-width~="xxl-27.5"] {
        width: 27.5% !important;
    }

    [data-width~="xxl-30"] {
        width: 30% !important;
    }

    [data-width~="xxl-32.5"] {
        width: 32.5% !important;
    }

    [data-width~="xxl-35"] {
        width: 35% !important;
    }

    [data-width~="xxl-37.5"] {
        width: 37.5% !important;
    }

    [data-width~="xxl-40"] {
        width: 40% !important;
    }

    [data-width~="xxl-42.5"] {
        width: 42.5% !important;
    }

    [data-width~="xxl-45"] {
        width: 45% !important;
    }

    [data-width~="xxl-47.5"] {
        width: 47.5% !important;
    }

    [data-width~="xxl-50"] {
        width: 50% !important;
    }

    [data-width~="xxl-52.5"] {
        width: 52.5% !important;
    }

    [data-width~="xxl-55"] {
        width: 55% !important;
    }

    [data-width~="xxl-57.5"] {
        width: 57.5% !important;
    }

    [data-width~="xxl-60"] {
        width: 60% !important;
    }

    [data-width~="xxl-62.5"] {
        width: 62.5% !important;
    }

    [data-width~="xxl-65"] {
        width: 65% !important;
    }

    [data-width~="xxl-67.5"] {
        width: 67.5% !important;
    }

    [data-width~="xxl-70"] {
        width: 70% !important;
    }

    [data-width~="xxl-72.5"] {
        width: 72.5% !important;
    }

    [data-width~="xxl-75"] {
        width: 75% !important;
    }

    [data-width~="xxl-77.5"] {
        width: 77.5% !important;
    }

    [data-width~="xxl-80"] {
        width: 80% !important;
    }

    [data-width~="xxl-82.5"] {
        width: 82.5% !important;
    }

    [data-width~="xxl-85"] {
        width: 85% !important;
    }

    [data-width~="xxl-87.5"] {
        width: 87.5% !important;
    }

    [data-width~="xxl-90"] {
        width: 90% !important;
    }

    [data-width~="xxl-92.5"] {
        width: 92.5% !important;
    }

    [data-width~="xxl-95"] {
        width: 95% !important;
    }

    [data-width~="xxl-97.5"] {
        width: 97.5% !important;
    }

    [data-width~="xxl-100"] {
        width: 100% !important;
    }

    [data-width~="xxl-33"] {
        width: 33.33333% !important;
    }
}

.DescriptionOverview {
    background-color: #f5fbff;
    padding-top: 15px;
    padding-bottom: 15px;
}

@media print, (min-width: 0.01px) {
    .DescriptionOverview {
        display: -webkit-box;
        display: flex;
    }
}

.DescriptionOverview-icon {
    margin-right: 7.5px;
}

.DescriptionOverview-text {
    margin-right: 30px;
}

@media print, (min-width: 0.01px) {
    .DescriptionOverview-text {
        -webkit-box-flex: 1;
        flex-grow: 1;
    }
}

.DescriptionOverview-text-title {
    color: #509bc2;
    margin-top: 0;
    margin-bottom: 0;
}

.DescriptionOverview-text-description {
    margin-top: 0;
    margin-bottom: 0;
}

.DirectoryList {
    padding-top: 15px;
    display: -webkit-box;
    display: flex;
}

.DirectoryList-filter {
    display: -webkit-box;
    display: flex;
    flex-shrink: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-right: 1px solid #bec8c2;
}

    .DirectoryList-filter .a,
    .DirectoryList-filter a {
        font-size: 1rem;
        line-height: 1.57143;
        width: 60px;
        text-align: center;
        display: block;
        color: inherit;
    }

        .DirectoryList-filter a.is-active,
        .DirectoryList-filter a:hover,
        .DirectoryList-filter a:active,
        .DirectoryList-filter a:focus {
            color: #509bc2;
        }

.DirectoryList-items {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-box-flex: 1;
    flex-grow: 1;
    position: relative;
}

    .DirectoryList-items ul {
        position: absolute;
    }

    .DirectoryList-items .a,
    .DirectoryList-items a {
        display: block;
        padding-left: 30px;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        color: inherit;
    }

        .DirectoryList-items a.is-active,
        .DirectoryList-items a:hover,
        .DirectoryList-items a:active,
        .DirectoryList-items a:focus {
            color: #509bc2;
            outline: none;
        }

.FarmTableGroup {
    border-radius: 5px;
    overflow: hidden;
    background-color: #d8deda;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.FarmTableGroup-heading {
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
    background-color: #566a89;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ffffff;
    -webkit-box-align: center;
    align-items: center;
}

    .FarmTableGroup-heading .a,
    .FarmTableGroup-heading a {
        color: inherit;
    }

.FarmTableGroup-heading--super {
    background-color: #36975c;
}

.FarmTable_wrapper {
    background-color: #ffffff;
    z-index: 10;
    border-radius: 3px;
    overflow-y: hidden;
    overflow-x: auto;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 1px #bec8c2;
    padding: 1px;
}

.Tile-body > .FarmTable_wrapper:first-child {
    margin-top: 0;
}

.Tile-body > .Tile-body--first-child:first-child {
    margin-top: 0;
}

h5 + .FarmTable_wrapper,
.ActionsBar + .FarmTable_wrapper {
    margin-top: 15px;
}

.FarmTable_wrapper + h5 {
    margin-top: 30px;
}

.FarmTable {
    background-color: #ffffff;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

    .FarmTable li {
        padding: 15px 10px;
        position: relative;
    }

@media print, (min-width: 0.01px) {
    .FarmTable li {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 1280px) {
    .FarmTable li {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.FarmTable li:not(:first-child):not(.FarmTable-title)::before {
    content: "";
    position: absolute;
    background-color: #bec8c2;
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;
}

@media print, (min-width: 0.01px) {
    .FarmTable li:not(:first-child):not(.FarmTable-title)::before {
        top: 15px;
        left: -1px;
        width: 1px;
        height: calc(100% - 30px);
    }
}

@media (min-width: 0.01px) and (max-width: 1279.99px) {
    .FarmTable li:first-child[data-width~="md-100"] + li::before,
    .FarmTable li.u-flexBreak-sm + li::before {
        width: 0 !important;
    }
}

@media (max-width: 0px) {
    .FarmTable-back {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

@media print, (min-width: 0.01px) {
    .FarmTable-back {
        padding-right: 0 !important;
    }
}

@media print, (min-width: 0.01px) {
    .FarmTable-back + .FarmTable-title {
        padding-left: 15px;
    }
}

.FarmTable-view {
    margin-left: auto;
}

@media (min-width: 1280px) {
    .FarmTable-view {
        -webkit-box-ordinal-group: 2147483648;
        order: 2147483647;
    }
}

.FarmTable-slideToggle::before,
.FarmTable-nutrientsLost::before {
    display: none;
}

@media (min-width: 0.01px) and (max-width: 1279.99px) {
    .FarmTable-view::before {
        display: none;
    }
}

.FarmTable-keyValuePair {
    display: -webkit-box;
    display: flex;
}

.FarmTable-keyValue {
    -webkit-box-flex: 1;
    flex-grow: 1;
}

    .FarmTable-keyValue.has-error > .FarmTable-key {
        color: #973b36;
    }

    .FarmTable-keyValue.has-warning > .FarmTable-key {
        color: #e39153;
    }

.FarmTable-key {
    font-size: 0.95rem;
    line-height: 1.66667;
    display: block;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .FarmTable-key {
        font-size: 0.89286rem;
    }
}

.FarmTable-value {
    display: block;
}

.FarmTable-value-link {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

    .FarmTable-value-link .icon {
        position: relative;
        top: -3px;
    }

.FarmTable-keyValue.has-error .FarmTable-value {
    font-size: 1rem;
    line-height: 1.28571;
    color: #973b36;
    background-color: #f8edec;
    padding: 7.5px;
}

.FarmTable-keyValue.has-warning .FarmTable-value {
    font-size: 1rem;
    line-height: 1.28571;
    color: #e39153;
    background-color: #f8edec;
    padding: 7.5px;
}

.UnpaidSubscription {
    color: #973b36;
}

    .UnpaidSubscription .a,
    .UnpaidSubscription a {
        color: #973b36;
        text-decoration: underline;
    }

.ExtendSubscription {
    color: #36975c;
}

    .ExtendSubscription .a,
    .ExtendSubscription a {
        color: #36975c;
        text-decoration: underline;
    }

.FarmTable-summaryIcons {
    flex-shrink: 0;
}

    .FarmTable-summaryIcons img {
        -webkit-filter: contrast(0) sepia(1) hue-rotate(90deg) brightness(125%) saturate(15%);
        filter: contrast(0) sepia(1) hue-rotate(90deg) brightness(125%) saturate(15%);
    }

.FarmTable-back,
.FarmTable-title,
.FarmTable-button,
.FarmTable-link,
.FarmTable-subscription,
.FarmTable-message,
.FarmTable-offline,
.FarmTable-nutrientsLost,
.FarmTable-view,
.FarmTable-slideToggle {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.FarmTable-item--ellipsis .FarmTable-keyValue {
    overflow: hidden;
}

.FarmTable-item--ellipsis .FarmTable-key,
.FarmTable-item--ellipsis .FarmTable-value-link-text,
.FarmTable-item--ellipsis .FarmTable-value {
    display: block;
}

.FarmTable-supplementary > * {
    margin-bottom: 10px;
}

.FarmTable-supplementary-table {
    border-radius: 3px;
    border: 1px solid #e5e9e7;
}

.FarmTable-supplementary-table-th {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 0.95rem;
    background-color: #f8f9f8;
    padding-top: 9px;
    padding-bottom: 8px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .FarmTable-supplementary-table-th {
        font-size: 0.89286rem;
    }
}

.FarmTable-supplementary-table-th:not(:first-child) {
    border-left: 1px solid #bec8c2;
}

.FarmTable-supplementary-table-td {
    border-top: 1px solid rgba(190, 200, 194, 0.6);
}

.FarmTable_wrapper .FarmTable-supplementary-table-td {
    margin-bottom: 0;
    border-bottom-width: 0;
}

[class*="FarmTable_wrapper--"] {
    position: relative;
    padding-left: 6px;
}

    [class*="FarmTable_wrapper--"]::before {
        content: "";
        position: absolute;
        width: 6px;
        left: 0;
        top: 0;
        bottom: 0;
    }

.FarmTable_wrapper--yellow::before {
    background-color: #ffba49;
}

.FarmTable_wrapper--orange::before {
    background-color: #f87666;
}

.FarmTable_wrapper--tan::before {
    background-color: #e39153;
}

.FarmTable_wrapper--red::before {
    background-color: #ad343e;
}

.FarmTable_wrapper--brick::before {
    background-color: #973b36;
}

.FarmTable_wrapper--magenta::before {
    background-color: #c84273;
}

.FarmTable_wrapper--grape::before {
    background-color: #8f5687;
}

.FarmTable_wrapper--purple::before {
    background-color: #7d63a5;
}

.FarmTable_wrapper--navy::before {
    background-color: #4c576c;
}

.FarmTable_wrapper--blue::before {
    background-color: #395998;
}

.FarmTable_wrapper--sky::before {
    background-color: #509bc2;
}

.FarmTitle {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 60px;
}

    .FarmTitle.FarmTitle {
        padding-top: 0;
        padding-bottom: 0;
    }

.FarmTitle-icon {
    position: relative;
    top: -6px;
    margin-right: 9px;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.FarmTitle-icon--lg {
    width: 60px !important;
    height: 60px !important;
}

.FarmTitle-icon.Print {
    top: -3px;
    margin-right: 4px;
}

.FarmTitle-heading {
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.31429rem;
    line-height: 1.16667;
}

.FarmTitle-heading--lg {
    font-size: 1.71429rem !important;
}

.FarmTitle-heading--sm {
    font-size: 1.11429rem !important;
}

.FarmTitle-heading.Print {
    font-size: 1.31429rem;
}

.FarmTitle--xl {
}

    .FarmTitle--xl .FarmTitle-icon {
        width: 75px !important;
        height: 75px !important;
    }

    .FarmTitle--xl .FarmTitle-name {
        font-size: 2rem !important;
        font-weight: 500;
        max-width: none;
    }

    .FarmTitle--xl .FarmTitle-address {
        font-size: 1.3rem !important;
        max-width: none;
    }

.FarmTable-title {
    min-height: 60px;
    margin-left: -6px;
}

    .FarmTable-title.FarmTable-title {
        padding-top: 7.5px;
        padding-bottom: 7.5px;
    }

@media (max-width: 0px) {
    .FarmTable-title {
        -webkit-box-align: initial;
        align-items: initial;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.FarmTable-title .FarmTitle {
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.FarmTable-title .NutrientsLost {
    margin-right: 30px;
}

@media (min-width: 1280px) {
    .FarmTable-title .NutrientsLost {
        display: none;
    }
}

.FarmTitle-link {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.FarmTitle-sub {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1px;
}

.FarmTitle-sub--sm {
    font-size: 0.9rem;
}

.FarmTitle-backLink {
    margin-right: 7.5px;
}

.FarmTitle,
.FarmTable-title {
    overflow: hidden;
}

.FarmTitle-name {
    max-width: calc(100% - 37.5px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.Footer {
    text-align: center;
    font-size: 0.95rem;
    color: #8d9590;
    padding-top: 15px;
    padding-bottom: 80px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Footer {
        font-size: 0.89286rem;
    }
}

.Footer-nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 7.5px;
}

@media (max-width: 0px) {
    .Footer-nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

.Footer-nav .a,
.Footer-nav a {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    padding: 3px 15px;
    position: relative;
}

@media print, (min-width: 0.01px) {
    .Footer-nav li:not(:first-child) a::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #bec8c2;
    }
}

.Checkbox {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

    .Checkbox:not(:last-child) {
        margin-bottom: 15px;
    }

    .Checkbox.is-disabled {
        pointer-events: none;
    }

        .Checkbox.is-disabled .Checkbox-label {
            color: #bec8c2;
        }

            .Checkbox.is-disabled .Checkbox-label::before {
                opacity: 0.3;
            }

.Checkbox-label {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: start;
    align-items: flex-start;
    padding: 2px;
}

    .Checkbox-label:empty::before {
        margin-right: 0;
    }

.Checkbox-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.Checkbox-label::before {
    flex-shrink: 0;
    content: "";
    display: block;
    text-align: center;
    color: transparent;
    width: 16px;
    height: 16px;
    box-shadow: 0 0 0 1px #bec8c2;
    margin-right: 15px;
    margin-top: 2px;
    background-color: #fff;
}

.Checkbox-input:focus + .Checkbox-label::before {
    box-shadow: 0 0 0 1px #36975c;
}

.Checkbox-input:checked + .Checkbox-label::before {
    color: #509bc2;
    background-color: #509bc2;
    box-shadow: 0 0 0 2px #509bc2, inset 0 0 0 3px #ffffff;
}

.Checkbox--tick .Checkbox-label::before {
    background-color: #ffffff !important;
}

.Checkbox--tick .Checkbox-input:checked + .Checkbox-label::before {
    color: #509bc2;
    box-shadow: 0 0 0 2px #509bc2;
}

.Checkbox--tick .Checkbox-input:checked + .Checkbox-label::after {
    position: absolute;
    content: "";
    z-index: 999;
    top: 5px;
    left: 8px;
    width: 5px;
    height: 11px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: solid #509bc2;
    border-width: 0 2px 2px 0;
}

.Checkbox--slim .Checkbox-label::before {
    margin-right: 5px;
}

.Field {
    margin-top: 20px;
}

    .Field .Fieldset > .Field:first-child,
    .Table td > .Field:first-child {
        margin-top: 0;
    }

.waiting .Field,
.offline .Field {
    pointer-events: none;
}
/*
div > .Grid > .Grid-cell:first-child > .Field:first-child {
    margin-top: 25px;
}
div > .Grid ~ .Grid > .Grid-cell:first-child > .Field:first-child {
    margin-top: 30px;
}
*/
h1 + .Field,
h2 + .Field,
h3 + .Field,
h4 + .Field,
h5 + .Field,
h6 + .Field,
p + .Field {
    margin-top: 15px;
}

.Field-label {
    display: block;
    color: #58585a;
    position: relative;
}

.Field.has-error .Field-label {
    color: #973b36;
}

.Field-label .required {
    position: relative;
    color: #973b36;
    font-size: 1.5rem;
    left: 3px;
    top: 6px;
}

.Field-label .help,
.Field .Checkbox .help {
    display: inline-block;
    cursor: help;
    position: relative;
    left: 6px;
    top: -1px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: #509bc2;
    color: #fff;
    text-align: center;
    line-height: 18px;
}

.help-tootip-link {
    display: inline-block;
    cursor: help;
    position: relative;
    left: 6px;
    top: -1px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: #509bc2;
    color: #fff;
    text-align: center;
    line-height: 18px;
}

.help-tooltip-container .help-tooltip {
    font-size: 12pt;
    max-width: 300px;
}

.Field .Checkbox .help {
    top: -4px;
}

.Field-label.is-disabled {
    color: #bec8c2;
}

.Field-error {
    font-size: 1rem;
    line-height: 1.28571;
    margin-top: 15px;
    color: #973b36;
    background-color: #f8edec;
    padding: 15px 15px;
    position: relative;
    display: none;
}

.Field.has-error .Field-error {
    display: block;
}

@media print, (min-width: 0.01px) {
    .Field-group {
        margin-top: 30px;
        display: -webkit-box;
        display: flex;
    }
}

@media print, print and (min-width: 0.01px), print and (min-width: 0.01px), (min-width: 0.01px) and (min-width: 0.01px) {
    .Field-group {
        margin-left: -7.5px;
        margin-right: -7.5px;
    }
}

@media print and (min-width: 1280px), (min-width: 0.01px) and (min-width: 1280px) {
    .Field-group {
        margin-left: -10px;
        margin-right: -10px;
    }
}

@media (max-width: 0px) {
    .Field-group .Field:first-child {
        margin-top: 22.5px;
    }
}

@media print, (min-width: 0.01px) {
    .Field-group .Field {
        margin-top: 0;
        min-width: 0;
    }
}

@media print, (min-width: 0.01px) {
    .Field-group .Field {
        padding-left: 7.5px;
        padding-right: 7.5px;
    }
}

@media (min-width: 1280px) {
    .Field-group .Field {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.Fieldset {
    margin-top: 20px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075);
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #e5e9e7;
}

    .Fieldset hr {
        margin-bottom: -15px;
    }

.Fieldset-heading {
    margin-top: -10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    background-color: #f8f9f8;
    border-bottom: 1px solid #e5e9e7;
}

@media print, (min-width: 0.01px) {
    .Fieldset-heading {
        margin-top: -15px;
    }
}

@media (min-width: 1280px) {
    .Fieldset-heading {
        margin-top: -20px;
    }
}

.Fieldset-heading-dl {
    margin-top: 0;
    margin-bottom: 0;
}

@media print, (min-width: 0.01px) {
    .Fieldset-heading-dl {
        display: -webkit-box;
        display: flex;
    }
}

@media (max-width: 0px) {
    .Fieldset-heading-dl dt {
        float: left;
    }
}

.Fieldset-heading-dl dt {
    margin-right: 3px;
}

@media print, (min-width: 0.01px) {
    .Fieldset-heading-dl dt:not(:first-child) {
        margin-left: 15px;
    }
}

@media (min-width: 1280px) {
    .Fieldset-heading-dl dt {
        margin-left: 30px;
    }
}

.Fieldset-heading-dl dd {
    margin-left: 0;
}

/*------------------------------------*  $FILE DROP
\*------------------------------------*/
.dropzone {
    margin-top: 15px;
    min-height: 100px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    background-color: #eeeeee;
    border: 2px dashed #bec8c2;
    overflow: hidden;
    overflow-y: scroll;
}

.Field.has-error .dropzone {
    border-color: #973b36;
}

.dropzone.dz-drag-hover {
    background-color: #f8f9f8;
    border-color: #509bc2;
}

.dz-message {
    text-align: center;
}

.dz-remove {
    margin-top: 7.5px;
    white-space: nowrap;
    display: table-cell;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

    .dz-remove:before {
        margin-right: 5px;
    }

    .dz-remove:hover {
        cursor: pointer;
    }

.dz-clickable {
    cursor: pointer;
}

.dz-started {
    border-style: solid;
    background-color: #ffffff;
    display: table;
}

    .dz-started .dz-message {
        display: none;
    }

.dz-preview {
    display: table-row;
    align-items: center;
}

.dz-details {
    display: table-cell;
}

.dz-file-preview {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.dz-filename {
    background-color: #f8f9f8;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    position: relative;
    display: block;
    z-index: 10;
    padding: 15px 30px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 7.5px;
}

.dz-progress {
    display: table-cell;
}

.dz-success-mark svg,
.dz-error-mark svg {
    display: none;
}

.dz-success-mark:before,
.dz-error-mark:before {
    font-size: 3.21429rem;
}

.dz-success-mark:before {
    color: #36975c;
}

.dz-error-mark:before {
    color: #973b36;
}

.filepicker-file-icon {
    position: relative;
    display: inline-block;
    margin: 1.5em 0 2.5em 0;
    padding-left: 45px;
    color: #000000;
}

    .filepicker-file-icon::before {
        position: absolute;
        top: -7px;
        left: 0;
        width: 29px;
        height: 34px;
        content: "";
        border: solid 2px #8d9590;
        border-radius: 2px;
    }

    .filepicker-file-icon::after {
        font-size: 11px;
        line-height: 1.3;
        position: absolute;
        top: 9px;
        left: -4px;
        padding: 0 2px;
        content: "file";
        content: attr(data-filetype);
        text-align: right;
        letter-spacing: 1px;
        color: #ffffff;
        background-color: #000000;
    }

.dz-started .filepicker-file-icon {
    display: none;
}

.Input_wrapper {
    position: relative;
}

.Input {
    font-size: 1rem;
    line-height: 2.5;
    height: 35px;
    color: #58585a;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    outline: none !important;
    border: none;
    border-radius: 0;
    padding: 0 0 0 0;
    box-shadow: 0 2px 0 #8d9590;
}

    .Input::-webkit-input-placeholder {
        color: #8d9590;
    }

    .Input::-moz-placeholder {
        color: #8d9590;
    }

    .Input:-ms-input-placeholder {
        color: #8d9590;
    }

    .Input:valid {
        box-shadow: 0 2px 0 #509bc2;
    }

    .Input:invalid {
        box-shadow: 0 2px 0 #8d9590;
    }

    .Input:focus {
        box-shadow: 0 2px 0 #36975c;
    }

    .Input[readonly],
    .Input:disabled,
    .Input--disabled,
    .offline .Input {
        box-shadow: 0 2px 0 #eeeeee;
        pointer-events: none;
    }

.Field.has-error .Input {
    box-shadow: 0 2px 0 #973b36;
}

td .Input {
    margin-bottom: 2px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

:required,
:invalid,
:-moz-submit-invalid,
:-moz-ui-invalid {
    box-shadow: none;
}

.Input.has-helper {
    padding-right: 30px;
}

.Input-helper {
    color: #8d9590;
    position: absolute;
    right: 0;
    bottom: 9px;
}

.Input-button {
    position: absolute;
    right: -205px;
    bottom: 0;
}

.Input.has-clear {
    padding-right: 30px;
}

.Input-clear {
    display: none;
    position: absolute;
    right: 0;
    bottom: 4px;
}

.Input.has-value + .Input-clear {
    display: block;
}

.Input-clear img {
    display: block;
}

.Input--block {
    display: block;
    width: 100%;
}

    .Input--block input,
    .Input--block textarea {
        width: 100%;
    }

.Radio_group {
    margin-top: 10px;
}

    .Radio_group:not(.Radio_group--inline) label + label {
        margin-top: 20px;
    }

@media print, (min-width: 0.01px) {
    .Radio_group--inline {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
    }

        .Radio_group--inline .Radio:not(:last-child) {
            margin-right: 60px;
        }
}

.Radio {
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 3px;
    margin-bottom: 3px;
}

@media (max-width: 0px) {
    .Radio:not(:last-child) {
        margin-bottom: 7.5px;
    }
}

.Radio-label {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    padding: 2px;
}

.Radio-input {
    z-index: 0;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}

.Radio-label::before {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    border: 3px solid #ffffff;
    box-shadow: 0 0 0 1px #bec8c2;
    border-radius: 50%;
    background: #fff;
    margin-right: 15px;
}

.Radio-input:focus + .Radio-label::before {
    box-shadow: 0 0 0 1px #36975c;
}

.Radio-input:checked + .Radio-label::before {
    background: #509bc2;
    box-shadow: 0 0 0 2px #509bc2;
}

.Select {
    position: relative;
    display: block;
}

    .Select select {
        color: #58585a;
        border-radius: 0;
        height: 35px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0 30px 0 0;
        outline: none;
        width: 100%;
        box-shadow: 0 2px 0 #8d9590;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23509bc2" fill-rule="evenodd" d="M12 15l6-6H6z"/></svg>');
        background-position: 100% calc(17.5px - 12px);
        background-repeat: no-repeat;
    }

        .Select select:valid {
            box-shadow: 0 2px 0 #509bc2;
        }

        .Select select:focus {
            box-shadow: 0 2px 0 #36975c;
        }

        .Select select:disabled,
        .offline .Select select {
            box-shadow: 0 2px 0 #eeeeee;
            background-image: none;
            padding-right: 0;
            pointer-events: none;
        }

.Select--inline select {
    height: 33px;
    font-weight: 600;
    padding: 2px 30px 0 15px !important;
    background-color: #fff !important;
    border-radius: 5px !important;
}

.Select--slim {
    margin-left: 7.5px;
}

    .Select--slim select {
        height: 25px;
        font-weight: 600;
        padding: 2px 30px 0 15px !important;
        background-position: 100% calc(13.5px - 12px);
        background-color: #fff !important;
        border-radius: 5px !important;
    }

.Field.has-error .Select select {
    box-shadow: 0 2px 0 #973b36;
    color: #973b36;
}

.Field.is-invalid .Select select {
    color: #9FA7A3;
}

.Select select option {
    color: #58585a;
}

.Select select.is-unpopulated {
    color: #8d9590;
}

.Select select::-ms-expand {
    display: none;
}

.has-error .Select select {
    box-shadow: 0 2px 0 #973b36;
    color: #973b36;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23973b36" fill-rule="evenodd" d="M12 15l6-6H6z"/></svg>');
}

.Select--sort {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

    .Select--sort select {
        height: 34px;
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px #bec8c2 !important;
        padding: 0 30px 0 15px;
        background-position: calc(100% - 5px) calc(17px - 12px);
    }

@media (min-width: 1280px) {
    .Select--sort select {
        min-width: 200px;
    }
}

.Select--sort-label {
    margin-right: 7.5px;
    white-space: nowrap;
}

.Textarea {
    position: relative;
    margin-top: 10px;
}

    .Textarea textarea {
        font-size: 1rem;
        display: block;
        line-height: 1.42857;
        color: #58585a;
        width: 100%;
        resize: vertical;
        box-shadow: 0 0 0 1px #509bc2;
        border: solid 1px #509bc2;
        border-radius: 5px;
        padding: 7.5px 15px;
        min-height: 100px;
        outline: none;
    }

        .Textarea textarea:focus {
            box-shadow: 0 0 0 1px #36975c;
            border-color: #36975c;
        }

        .Textarea textarea:read-only {
            box-shadow: 0 0 0 1px #8d9590;
            border-color: #8d9590;
            cursor: default;
        }

.Field.has-error .Textarea {
    padding-bottom: 0;
}

    .Field.has-error .Textarea textarea {
        box-shadow: inset 0 0 5px 0 rgba(151, 59, 54, 0.5);
        border-color: #973b36;
        color: #973b36;
    }

form h3 {
    margin-top: 20px;
}

.Map {
    position: relative;
}

#map_wrapper {
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 800px;
}

.Map-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 95%;
}

.Map-element {
    width: 100%;
    height: 100%;
}

.Map-soilTable {
    position: absolute;
    top: 10px;
    right: 10px;
    max-height: calc(100% - 125px);
    width: 400px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px 15px;
    border-radius: 4px;
    overflow-y: auto;
}

    .Map-soilTable.expand {
        width: calc(100% - 90px);
    }

    .Map-soilTable .Table {
        background-color: #ffffff;
    }

.MapOverlay--text {
    color: #000000;
}

    .MapOverlay--text.small {
        font-size: 12px;
    }

.Map-info {
    position: absolute;
    z-index: 10;
    top: 45px;
    right: 15px;
    padding: 7.5px 15px;
    display: -webkit-box;
    display: flex;
}

    .Map-info .icon {
        margin-right: 7.5px;
        flex-shrink: 0;
    }

.Map-info-text > :first-child {
    margin-top: 3.75px;
}

.Calendar-slots--fruit td::after {
    background-color: #ebe6ed;
}

.Calendar-slots--fruit td[class*="up-"]::before {
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 47.5%, #ebe6ed 52.5%) no-repeat;
}

.Calendar-slots--fruit td[class*="down-"]::before {
    height: 100%;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 47.5%, #ebe6ed 52.5%) no-repeat;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Calendar-slots--fruit td[class*="up-"]::before {
        height: 100%;
        background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, #ebe6ed 50%) no-repeat;
    }

    .Calendar-slots--fruit td[class*="down-"]::before {
        height: 100%;
        background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 50%, #ebe6ed 50%) no-repeat;
    }
}

.Calendar-slots--crop td::after {
    background-color: #e4f5eb;
}

.Calendar-slots--crop td[class*="up-"]::before {
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 47.5%, #e4f5eb 52.5%) no-repeat;
}

.Calendar-slots--crop td[class*="down-"]::before {
    height: 100%;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 47.5%, #e4f5eb 52.5%) no-repeat;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Calendar-slots--crop td[class*="up-"]::before {
        height: 100%;
        background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, #e4f5eb 50%) no-repeat;
    }

    .Calendar-slots--crop td[class*="down-"]::before {
        height: 100%;
        background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 50%, #e4f5eb 50%) no-repeat;
    }
}

.Calendar-slots--irrigation td::after {
    background-color: #ebe6ed;
}

.Calendar-slots--irrigation td[class*="up-"]::before {
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 47.5%, #ebe6ed 52.5%) no-repeat;
}

.Calendar-slots--irrigation td[class*="down-"]::before {
    height: 100%;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 47.5%, #ebe6ed 52.5%) no-repeat;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Calendar-slots--irrigation td[class*="up-"]::before {
        height: 100%;
        background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, #ebe6ed 50%) no-repeat;
    }

    .Calendar-slots--irrigation td[class*="down-"]::before {
        height: 100%;
        background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 50%, #ebe6ed 50%) no-repeat;
    }
}

.Calendar-slots--pasture td::after {
    background-color: #4c576c;
}

.Calendar-slots--pasture td[class*="up-"]::before {
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 47.5%, #4c576c 52.5%) no-repeat;
}

.Calendar-slots--pasture td[class*="down-"]::before {
    height: 100%;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 47.5%, #4c576c 52.5%) no-repeat;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Calendar-slots--pasture td[class*="up-"]::before {
        height: 100%;
        background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, #4c576c 50%) no-repeat;
    }

    .Calendar-slots--pasture td[class*="down-"]::before {
        height: 100%;
        background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 50%, #4c576c 50%) no-repeat;
    }
}

.Calendar-slots--supplements td::after {
    background-color: #509bc2;
}

.Calendar-slots--supplements td[class*="up-"]::before {
    height: 100%;
    background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 47.5%, #509bc2 52.5%) no-repeat;
}

.Calendar-slots--supplements td[class*="down-"]::before {
    height: 100%;
    background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 47.5%, #509bc2 52.5%) no-repeat;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .Calendar-slots--supplements td[class*="up-"]::before {
        height: 100%;
        background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 50%, #509bc2 50%) no-repeat;
    }

    .Calendar-slots--supplements td[class*="down-"]::before {
        height: 100%;
        background: linear-gradient(to bottom left, rgba(255, 255, 255, 0) 50%, #509bc2 50%) no-repeat;
    }
}

.Calendar-slots td {
    position: relative;
}

    .Calendar-slots td::before,
    .Calendar-slots td::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
    }

    .Calendar-slots td[class*="of"]::before {
        background-position-y: 100%;
    }

    .Calendar-slots td[class*="of"]::after,
    .Calendar-slots td[class*="flat"]::after {
        bottom: 0;
    }

    .Calendar-slots td[class*="of1"]::before {
        background-size: 100% 100%;
    }

    .Calendar-slots td[class*="flat-1-12s"]::after {
        height: 8.33333%;
    }

    .Calendar-slots td[class*="1of1"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of1"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="of2"]::before {
        background-size: 100% 50%;
    }

    .Calendar-slots td[class*="flat-2-12s"]::after {
        height: 16.66667%;
    }

    .Calendar-slots td[class*="1of2"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of2"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of2"]::before {
        bottom: 50%;
    }

    .Calendar-slots td[class*="2of2"]::after {
        height: 50%;
    }

    .Calendar-slots td[class*="of3"]::before {
        background-size: 100% 33.33333%;
    }

    .Calendar-slots td[class*="flat-3-12s"]::after {
        height: 25%;
    }

    .Calendar-slots td[class*="1of3"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of3"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of3"]::before {
        bottom: 33.33333%;
    }

    .Calendar-slots td[class*="2of3"]::after {
        height: 33.33333%;
    }

    .Calendar-slots td[class*="3of3"]::before {
        bottom: 66.66667%;
    }

    .Calendar-slots td[class*="3of3"]::after {
        height: 66.66667%;
    }

    .Calendar-slots td[class*="of4"]::before {
        background-size: 100% 25%;
    }

    .Calendar-slots td[class*="flat-4-12s"]::after {
        height: 33.33333%;
    }

    .Calendar-slots td[class*="1of4"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of4"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of4"]::before {
        bottom: 25%;
    }

    .Calendar-slots td[class*="2of4"]::after {
        height: 25%;
    }

    .Calendar-slots td[class*="3of4"]::before {
        bottom: 50%;
    }

    .Calendar-slots td[class*="3of4"]::after {
        height: 50%;
    }

    .Calendar-slots td[class*="4of4"]::before {
        bottom: 75%;
    }

    .Calendar-slots td[class*="4of4"]::after {
        height: 75%;
    }

    .Calendar-slots td[class*="of5"]::before {
        background-size: 100% 20%;
    }

    .Calendar-slots td[class*="flat-5-12s"]::after {
        height: 41.66667%;
    }

    .Calendar-slots td[class*="1of5"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of5"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of5"]::before {
        bottom: 20%;
    }

    .Calendar-slots td[class*="2of5"]::after {
        height: 20%;
    }

    .Calendar-slots td[class*="3of5"]::before {
        bottom: 40%;
    }

    .Calendar-slots td[class*="3of5"]::after {
        height: 40%;
    }

    .Calendar-slots td[class*="4of5"]::before {
        bottom: 60%;
    }

    .Calendar-slots td[class*="4of5"]::after {
        height: 60%;
    }

    .Calendar-slots td[class*="5of5"]::before {
        bottom: 80%;
    }

    .Calendar-slots td[class*="5of5"]::after {
        height: 80%;
    }

    .Calendar-slots td[class*="of6"]::before {
        background-size: 100% 16.66667%;
    }

    .Calendar-slots td[class*="flat-6-12s"]::after {
        height: 50%;
    }

    .Calendar-slots td[class*="1of6"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of6"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of6"]::before {
        bottom: 16.66667%;
    }

    .Calendar-slots td[class*="2of6"]::after {
        height: 16.66667%;
    }

    .Calendar-slots td[class*="3of6"]::before {
        bottom: 33.33333%;
    }

    .Calendar-slots td[class*="3of6"]::after {
        height: 33.33333%;
    }

    .Calendar-slots td[class*="4of6"]::before {
        bottom: 50%;
    }

    .Calendar-slots td[class*="4of6"]::after {
        height: 50%;
    }

    .Calendar-slots td[class*="5of6"]::before {
        bottom: 66.66667%;
    }

    .Calendar-slots td[class*="5of6"]::after {
        height: 66.66667%;
    }

    .Calendar-slots td[class*="6of6"]::before {
        bottom: 83.33333%;
    }

    .Calendar-slots td[class*="6of6"]::after {
        height: 83.33333%;
    }

    .Calendar-slots td[class*="of7"]::before {
        background-size: 100% 14.28571%;
    }

    .Calendar-slots td[class*="flat-7-12s"]::after {
        height: 58.33333%;
    }

    .Calendar-slots td[class*="1of7"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of7"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of7"]::before {
        bottom: 14.28571%;
    }

    .Calendar-slots td[class*="2of7"]::after {
        height: 14.28571%;
    }

    .Calendar-slots td[class*="3of7"]::before {
        bottom: 28.57143%;
    }

    .Calendar-slots td[class*="3of7"]::after {
        height: 28.57143%;
    }

    .Calendar-slots td[class*="4of7"]::before {
        bottom: 42.85714%;
    }

    .Calendar-slots td[class*="4of7"]::after {
        height: 42.85714%;
    }

    .Calendar-slots td[class*="5of7"]::before {
        bottom: 57.14286%;
    }

    .Calendar-slots td[class*="5of7"]::after {
        height: 57.14286%;
    }

    .Calendar-slots td[class*="6of7"]::before {
        bottom: 71.42857%;
    }

    .Calendar-slots td[class*="6of7"]::after {
        height: 71.42857%;
    }

    .Calendar-slots td[class*="7of7"]::before {
        bottom: 85.71429%;
    }

    .Calendar-slots td[class*="7of7"]::after {
        height: 85.71429%;
    }

    .Calendar-slots td[class*="of8"]::before {
        background-size: 100% 12.5%;
    }

    .Calendar-slots td[class*="flat-8-12s"]::after {
        height: 66.66667%;
    }

    .Calendar-slots td[class*="1of8"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of8"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of8"]::before {
        bottom: 12.5%;
    }

    .Calendar-slots td[class*="2of8"]::after {
        height: 12.5%;
    }

    .Calendar-slots td[class*="3of8"]::before {
        bottom: 25%;
    }

    .Calendar-slots td[class*="3of8"]::after {
        height: 25%;
    }

    .Calendar-slots td[class*="4of8"]::before {
        bottom: 37.5%;
    }

    .Calendar-slots td[class*="4of8"]::after {
        height: 37.5%;
    }

    .Calendar-slots td[class*="5of8"]::before {
        bottom: 50%;
    }

    .Calendar-slots td[class*="5of8"]::after {
        height: 50%;
    }

    .Calendar-slots td[class*="6of8"]::before {
        bottom: 62.5%;
    }

    .Calendar-slots td[class*="6of8"]::after {
        height: 62.5%;
    }

    .Calendar-slots td[class*="7of8"]::before {
        bottom: 75%;
    }

    .Calendar-slots td[class*="7of8"]::after {
        height: 75%;
    }

    .Calendar-slots td[class*="8of8"]::before {
        bottom: 87.5%;
    }

    .Calendar-slots td[class*="8of8"]::after {
        height: 87.5%;
    }

    .Calendar-slots td[class*="of9"]::before {
        background-size: 100% 11.11111%;
    }

    .Calendar-slots td[class*="flat-9-12s"]::after {
        height: 75%;
    }

    .Calendar-slots td[class*="1of9"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of9"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of9"]::before {
        bottom: 11.11111%;
    }

    .Calendar-slots td[class*="2of9"]::after {
        height: 11.11111%;
    }

    .Calendar-slots td[class*="3of9"]::before {
        bottom: 22.22222%;
    }

    .Calendar-slots td[class*="3of9"]::after {
        height: 22.22222%;
    }

    .Calendar-slots td[class*="4of9"]::before {
        bottom: 33.33333%;
    }

    .Calendar-slots td[class*="4of9"]::after {
        height: 33.33333%;
    }

    .Calendar-slots td[class*="5of9"]::before {
        bottom: 44.44444%;
    }

    .Calendar-slots td[class*="5of9"]::after {
        height: 44.44444%;
    }

    .Calendar-slots td[class*="6of9"]::before {
        bottom: 55.55556%;
    }

    .Calendar-slots td[class*="6of9"]::after {
        height: 55.55556%;
    }

    .Calendar-slots td[class*="7of9"]::before {
        bottom: 66.66667%;
    }

    .Calendar-slots td[class*="7of9"]::after {
        height: 66.66667%;
    }

    .Calendar-slots td[class*="8of9"]::before {
        bottom: 77.77778%;
    }

    .Calendar-slots td[class*="8of9"]::after {
        height: 77.77778%;
    }

    .Calendar-slots td[class*="9of9"]::before {
        bottom: 88.88889%;
    }

    .Calendar-slots td[class*="9of9"]::after {
        height: 88.88889%;
    }

    .Calendar-slots td[class*="of10"]::before {
        background-size: 100% 10%;
    }

    .Calendar-slots td[class*="flat-10-12s"]::after {
        height: 83.33333%;
    }

    .Calendar-slots td[class*="1of10"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of10"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of10"]::before {
        bottom: 10%;
    }

    .Calendar-slots td[class*="2of10"]::after {
        height: 10%;
    }

    .Calendar-slots td[class*="3of10"]::before {
        bottom: 20%;
    }

    .Calendar-slots td[class*="3of10"]::after {
        height: 20%;
    }

    .Calendar-slots td[class*="4of10"]::before {
        bottom: 30%;
    }

    .Calendar-slots td[class*="4of10"]::after {
        height: 30%;
    }

    .Calendar-slots td[class*="5of10"]::before {
        bottom: 40%;
    }

    .Calendar-slots td[class*="5of10"]::after {
        height: 40%;
    }

    .Calendar-slots td[class*="6of10"]::before {
        bottom: 50%;
    }

    .Calendar-slots td[class*="6of10"]::after {
        height: 50%;
    }

    .Calendar-slots td[class*="7of10"]::before {
        bottom: 60%;
    }

    .Calendar-slots td[class*="7of10"]::after {
        height: 60%;
    }

    .Calendar-slots td[class*="8of10"]::before {
        bottom: 70%;
    }

    .Calendar-slots td[class*="8of10"]::after {
        height: 70%;
    }

    .Calendar-slots td[class*="9of10"]::before {
        bottom: 80%;
    }

    .Calendar-slots td[class*="9of10"]::after {
        height: 80%;
    }

    .Calendar-slots td[class*="10of10"]::before {
        bottom: 90%;
    }

    .Calendar-slots td[class*="10of10"]::after {
        height: 90%;
    }

    .Calendar-slots td[class*="of11"]::before {
        background-size: 100% 9.09091%;
    }

    .Calendar-slots td[class*="flat-11-12s"]::after {
        height: 91.66667%;
    }

    .Calendar-slots td[class*="1of11"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of11"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of11"]::before {
        bottom: 9.09091%;
    }

    .Calendar-slots td[class*="2of11"]::after {
        height: 9.09091%;
    }

    .Calendar-slots td[class*="3of11"]::before {
        bottom: 18.18182%;
    }

    .Calendar-slots td[class*="3of11"]::after {
        height: 18.18182%;
    }

    .Calendar-slots td[class*="4of11"]::before {
        bottom: 27.27273%;
    }

    .Calendar-slots td[class*="4of11"]::after {
        height: 27.27273%;
    }

    .Calendar-slots td[class*="5of11"]::before {
        bottom: 36.36364%;
    }

    .Calendar-slots td[class*="5of11"]::after {
        height: 36.36364%;
    }

    .Calendar-slots td[class*="6of11"]::before {
        bottom: 45.45455%;
    }

    .Calendar-slots td[class*="6of11"]::after {
        height: 45.45455%;
    }

    .Calendar-slots td[class*="7of11"]::before {
        bottom: 54.54545%;
    }

    .Calendar-slots td[class*="7of11"]::after {
        height: 54.54545%;
    }

    .Calendar-slots td[class*="8of11"]::before {
        bottom: 63.63636%;
    }

    .Calendar-slots td[class*="8of11"]::after {
        height: 63.63636%;
    }

    .Calendar-slots td[class*="9of11"]::before {
        bottom: 72.72727%;
    }

    .Calendar-slots td[class*="9of11"]::after {
        height: 72.72727%;
    }

    .Calendar-slots td[class*="10of11"]::before {
        bottom: 81.81818%;
    }

    .Calendar-slots td[class*="10of11"]::after {
        height: 81.81818%;
    }

    .Calendar-slots td[class*="11of11"]::before {
        bottom: 90.90909%;
    }

    .Calendar-slots td[class*="11of11"]::after {
        height: 90.90909%;
    }

    .Calendar-slots td[class*="of12"]::before {
        background-size: 100% 8.33333%;
    }

    .Calendar-slots td[class*="flat-12-12s"]::after {
        height: 100%;
    }

    .Calendar-slots td[class*="1of12"]::before {
        bottom: 0%;
    }

    .Calendar-slots td[class*="1of12"]::after {
        height: 0%;
    }

    .Calendar-slots td[class*="2of12"]::before {
        bottom: 8.33333%;
    }

    .Calendar-slots td[class*="2of12"]::after {
        height: 8.33333%;
    }

    .Calendar-slots td[class*="3of12"]::before {
        bottom: 16.66667%;
    }

    .Calendar-slots td[class*="3of12"]::after {
        height: 16.66667%;
    }

    .Calendar-slots td[class*="4of12"]::before {
        bottom: 25%;
    }

    .Calendar-slots td[class*="4of12"]::after {
        height: 25%;
    }

    .Calendar-slots td[class*="5of12"]::before {
        bottom: 33.33333%;
    }

    .Calendar-slots td[class*="5of12"]::after {
        height: 33.33333%;
    }

    .Calendar-slots td[class*="6of12"]::before {
        bottom: 41.66667%;
    }

    .Calendar-slots td[class*="6of12"]::after {
        height: 41.66667%;
    }

    .Calendar-slots td[class*="7of12"]::before {
        bottom: 50%;
    }

    .Calendar-slots td[class*="7of12"]::after {
        height: 50%;
    }

    .Calendar-slots td[class*="8of12"]::before {
        bottom: 58.33333%;
    }

    .Calendar-slots td[class*="8of12"]::after {
        height: 58.33333%;
    }

    .Calendar-slots td[class*="9of12"]::before {
        bottom: 66.66667%;
    }

    .Calendar-slots td[class*="9of12"]::after {
        height: 66.66667%;
    }

    .Calendar-slots td[class*="10of12"]::before {
        bottom: 75%;
    }

    .Calendar-slots td[class*="10of12"]::after {
        height: 75%;
    }

    .Calendar-slots td[class*="11of12"]::before {
        bottom: 83.33333%;
    }

    .Calendar-slots td[class*="11of12"]::after {
        height: 83.33333%;
    }

    .Calendar-slots td[class*="12of12"]::before {
        bottom: 91.66667%;
    }

    .Calendar-slots td[class*="12of12"]::after {
        height: 91.66667%;
    }

.Header {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background: #364052;
    height: 60px;
    padding-right: 10px;
}

@media (min-width: 1280px) {
    .Header {
        padding-right: 20px;
    }
}

.Header--offline {
    background: #973b36;
}

.Header--green {
    background: #36975c;
}

.Header--orange {
    background: #e39153;
}

.Header-logo {
    display: -webkit-box;
    display: flex;
}

    .Header-logo .a,
    .Header-logo a {
        position: relative;
        top: 2px;
    }

    .Header-logo img {
        height: 36px;
        width: 76px;
        flex-shrink: 0;
    }

@media (min-width: 1024px) {
    .Header-logo-sm {
        display: none;
    }
}

.Header-logo-lg {
    display: none;
}

@media (min-width: 1024px) {
    .Header-logo-lg {
        display: block;
    }
}

.Header-logo-lg img {
    width: 180px;
}

.Header-logo-print {
    display: none;
    width: 133px;
    height: 33px;
}

[class*="Heading--"] {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 45px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 3px;
}

    [class*="Heading--"] .a,
    [class*="Heading--"] a {
        color: inherit;
    }

.Heading--primary {
    background-color: #364052;
}

.Heading--secondary {
    background-color: #509bc2;
}

.Help {
    z-index: 30;
    bottom: 12px;
    right: 10px;
}

    .Help.Help {
        position: fixed;
    }

@media print, (min-width: 0.01px) {
    .Help {
        right: 15px;
    }
}

@media (min-width: 1280px) {
    .Help {
        right: 20px;
    }
}

.Help img {
    -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
}

.FilterPanel {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.14);
}

.HeroPanel {
    background-color: #ffffff;
    padding: 10px 20px 30px 20px;
    border-radius: 7px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.14);
}

.HeroPanel-heading {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 16px;
}

.HeroPanel-title {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 16px;
}

.HeroPanel--nloss::before {
    background-color: #44aa6c;
}

.HeroPanel--ploss::before {
    background-color: #e39153;
}

.HeroPanel--kloss::before {
    background-color: #808000;
}

.HeroPanel--sloss::before {
    background-color: #E6194B;
}

.HeroPanel--ghg::before {
    background-color: #4285f4;
}

.HeroPanel--ghg::before {
    background-color: #4285f4;
}

.HeroPanel--color::before {
    background-color: var(--var-color);
}

[class*="HeroPanel--"] {
    position: relative;
}

    [class*="HeroPanel--"]::before {
        content: "";
        position: absolute;
        width: 12px;
        left: -1px;
        top: -1px;
        bottom: -1px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

.HeroValue {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.HeroValue-item {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    padding-left: 30px;
    padding-right: 30px;
}

    .HeroValue-item.compressed {
        padding-left: 20px;
        padding-right: 20px;
    }

    .HeroValue-item h5 {
        margin: 0;
    }

    .HeroValue-item:not(:last-child) {
        border-right: 1px solid #e5e9e7;
    }

    .HeroValue-item:first-child {
        padding-left: 15px;
    }

    .HeroValue-item:last-child {
        padding-right: 15px;
    }

[class*="Hint"] {
    position: relative;
}

    [class*="Hint"]::before,
    [class*="Hint"]::after {
        position: absolute;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: hidden;
        opacity: 0;
        z-index: 20;
        pointer-events: none;
        transition: 0.15s ease;
        transition-delay: 0;
    }

    [class*="Hint"]:hover::before,
    [class*="Hint"]:hover::after {
        visibility: visible;
        opacity: 1;
    }

    [class*="Hint"]:hover::before,
    [class*="Hint"]:hover::after {
        transition-delay: 100ms;
    }

    [class*="Hint"]::before {
        content: "";
        position: absolute;
        background-color: transparent;
        border: 6px solid transparent;
        z-index: 21;
    }

    [class*="Hint"]::after {
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        padding: 6px 10px 8px;
        white-space: nowrap;
        border-radius: 3px;
    }

    [class*="Hint"][aria-label]::after {
        content: attr(aria-label);
    }

[aria-label=""]::before,
[aria-label=""]::after {
    display: none !important;
}

.Hint--top-left::before {
    border-top-color: rgba(0, 0, 0, 0.5);
}

.Hint--top-left::before {
    margin-bottom: -11px;
}

.Hint--top-left::before,
.Hint--top-left::after {
    bottom: 100%;
    left: 50%;
}

.Hint--top-left::before {
    left: calc(50% - 6px);
}

.Hint--top-left::after {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
}

.Hint--top-left::after {
    margin-left: 12px;
}

.Hint--top-left:hover::before {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
}

.Hint--top-left:hover::after {
    -webkit-transform: translateX(-100%) translateY(-8px);
    transform: translateX(-100%) translateY(-8px);
}

.Hint--small::after,
.Hint--medium::after,
.Hint--large::after {
    font-size: 1rem;
    line-height: 1.42857;
    white-space: normal;
    word-wrap: break-word;
}

.Hint--small::after {
    width: 110px;
}

.Hint--medium::after {
    width: 200px;
}

.Hint--large::after {
    width: 300px;
}

[class*="Hint--"]::after {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075);
}

[class*="IconLink--"] {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
}

[class*="IconLink--"]:after,
[class*="IconLink--"]:before {
    font-size: 1.71429rem;
    color: inherit;
}

    [class*="IconLink--"].is-disabled {
        pointer-events: none;
        opacity: 0.4;
    }

.IconText {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

    .IconText .icon {
        margin-right: 5px;
    }

@-webkit-keyframes infiniFade {
    0% {
        opacity: 0;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    30% {
        opacity: 1;
    }

    60% {
        opacity: 1;
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
        animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    }

    95% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes infiniFade {
    0% {
        opacity: 0;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    30% {
        opacity: 1;
    }

    60% {
        opacity: 1;
        -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
        animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
    }

    95% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes infiniMove {
    0% {
        bottom: 0;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    35% {
        bottom: 12px;
    }

    70% {
        bottom: 12px;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    95% {
        bottom: 24px;
    }

    100% {
        bottom: 24px;
    }
}

@keyframes infiniMove {
    0% {
        bottom: 0;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    35% {
        bottom: 12px;
    }

    70% {
        bottom: 12px;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
    }

    95% {
        bottom: 24px;
    }

    100% {
        bottom: 24px;
    }
}

.InfinLoader {
    display: inline-block;
    position: relative;
    width: 120px;
    height: 40px;
}

    .InfinLoader * {
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: #36975c;
        -webkit-animation: infiniMove 0.825s infinite, infiniFade 0.825s infinite;
        animation: infiniMove 0.825s infinite, infiniFade 0.825s infinite;
    }

        .InfinLoader *:nth-child(1) {
            -webkit-animation-delay: -0.20625s;
            animation-delay: -0.20625s;
            right: 0px;
        }

        .InfinLoader *:nth-child(2) {
            -webkit-animation-delay: -0.4125s;
            animation-delay: -0.4125s;
            right: 52px;
        }

        .InfinLoader *:nth-child(3) {
            -webkit-animation-delay: -0.61875s;
            animation-delay: -0.61875s;
            right: 104px;
        }

.List--templateIndex li {
    border-bottom: 1px dotted #e5e9e7;
}

.List--templateIndex .a,
.List--templateIndex a {
    padding-top: 5px;
    padding-bottom: 5px;
}

@-webkit-keyframes pulse {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.Loader {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 35px;
}

    .Loader * {
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        height: 35px;
        width: 35px;
    }

.Loader--tiny,
.Loader--tiny * {
    height: 9px !important;
    width: 22px !important;
}

.Loader--small,
.Loader--small * {
    height: 15px !important;
    width: 15px !important;
}

.Loader--results,
.Loader--results * {
    height: 10px !important;
    width: 19px !important;
}

button > .Loader--small {
    left: -20px;
    margin-left: 20px;
}

.Tile-head > .Loader,
.FarmTableGroup-heading > .Loader {
    height: 20px !important;
}

    .Tile-head > .Loader *,
    .FarmTableGroup-heading > .Loader * {
        height: 18px !important;
        width: 20px !important;
    }

.Tile-loader {
    display: none;
}

.Tile-loading > .Tile-body > .Tile-loader {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.SubMenu-item .Loader {
    height: 18px !important;
}

    .SubMenu-item .Loader * {
        height: 18px !important;
        width: 20px !important;
    }

.Loader *::before,
.Loader *::after {
    -webkit-animation: pulse 1s infinite;
    animation: pulse 1s infinite;
    content: "";
    position: absolute;
    left: 16px;
    height: 3px;
    width: 3px;
    border-radius: 1.5px;
    background-color: #ffffff;
}

.Loader--dark *::before,
.Loader--dark *::after {
    background-color: #58585a !important;
}

.Loader *::before {
    top: 0;
}

.Loader *::after {
    bottom: 0;
}

.Loader *:nth-child(1) {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
}

    .Loader *:nth-child(1)::before {
        -webkit-animation-delay: -0.08333s;
        animation-delay: -0.08333s;
    }

    .Loader *:nth-child(1)::after {
        -webkit-animation-delay: -0.58333s;
        animation-delay: -0.58333s;
    }

.Loader *:nth-child(2) {
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

    .Loader *:nth-child(2)::before {
        -webkit-animation-delay: -0.16667s;
        animation-delay: -0.16667s;
    }

    .Loader *:nth-child(2)::after {
        -webkit-animation-delay: -0.66667s;
        animation-delay: -0.66667s;
    }

.Loader *:nth-child(3) {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

    .Loader *:nth-child(3)::before {
        -webkit-animation-delay: -0.25s;
        animation-delay: -0.25s;
    }

    .Loader *:nth-child(3)::after {
        -webkit-animation-delay: -0.75s;
        animation-delay: -0.75s;
    }

.Loader *:nth-child(4) {
    -webkit-transform: rotate(-120deg);
    transform: rotate(-120deg);
}

    .Loader *:nth-child(4)::before {
        -webkit-animation-delay: -0.33333s;
        animation-delay: -0.33333s;
    }

    .Loader *:nth-child(4)::after {
        -webkit-animation-delay: -0.83333s;
        animation-delay: -0.83333s;
    }

.Loader *:nth-child(5) {
    -webkit-transform: rotate(-150deg);
    transform: rotate(-150deg);
}

    .Loader *:nth-child(5)::before {
        -webkit-animation-delay: -0.41667s;
        animation-delay: -0.41667s;
    }

    .Loader *:nth-child(5)::after {
        -webkit-animation-delay: -0.91667s;
        animation-delay: -0.91667s;
    }

.Loader *:nth-child(6) {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

    .Loader *:nth-child(6)::before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }

    .Loader *:nth-child(6)::after {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

.Loader *:nth-child(7) {
    -webkit-transform: rotate(-210deg);
    transform: rotate(-210deg);
}

    .Loader *:nth-child(7)::before {
        -webkit-animation-delay: -0.58333s;
        animation-delay: -0.58333s;
    }

    .Loader *:nth-child(7)::after {
        -webkit-animation-delay: -1.08333s;
        animation-delay: -1.08333s;
    }

.Loader *:nth-child(8) {
    -webkit-transform: rotate(-240deg);
    transform: rotate(-240deg);
}

    .Loader *:nth-child(8)::before {
        -webkit-animation-delay: -0.66664s;
        animation-delay: -0.66664s;
    }

    .Loader *:nth-child(8)::after {
        -webkit-animation-delay: -1.16664s;
        animation-delay: -1.16664s;
    }

.Loader *:nth-child(9) {
    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
}

    .Loader *:nth-child(9)::before {
        -webkit-animation-delay: -0.74997s;
        animation-delay: -0.74997s;
    }

    .Loader *:nth-child(9)::after {
        -webkit-animation-delay: -1.24997s;
        animation-delay: -1.24997s;
    }

.Loader *:nth-child(10) {
    -webkit-transform: rotate(-300deg);
    transform: rotate(-300deg);
}

    .Loader *:nth-child(10)::before {
        -webkit-animation-delay: -0.8333s;
        animation-delay: -0.8333s;
    }

    .Loader *:nth-child(10)::after {
        -webkit-animation-delay: -1.3333s;
        animation-delay: -1.3333s;
    }

.Loader *:nth-child(11) {
    -webkit-transform: rotate(-330deg);
    transform: rotate(-330deg);
}

    .Loader *:nth-child(11)::before {
        -webkit-animation-delay: -0.91663s;
        animation-delay: -0.91663s;
    }

    .Loader *:nth-child(11)::after {
        -webkit-animation-delay: -1.41663s;
        animation-delay: -1.41663s;
    }

.Loader *:nth-child(12) {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
}

    .Loader *:nth-child(12)::before {
        -webkit-animation-delay: -0.99996s;
        animation-delay: -0.99996s;
    }

    .Loader *:nth-child(12)::after {
        -webkit-animation-delay: -1.49996s;
        animation-delay: -1.49996s;
    }

.react-page.modal-is-open .Modal_wrapper {
    display: -webkit-box;
    display: flex;
}

.Modal_wrapper {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    z-index: 20;
}

    .Modal_wrapper.Print {
        background-color: #ffffff;
    }

.Modal {
    width: 100%;
    max-width: 114.28571rem;
    position: relative;
    padding-top: 45px;
    padding-bottom: 45px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.075);
    margin: auto;
    align-self: flex-start;
}

    .Modal.Print {
        box-shadow: none;
        margin-top: 0px;
    }

.Modal-head {
    background-color: #44aa6c;
    margin-top: -45px;
    border-bottom: 1px solid #e5e9e7;
    color: #ffffff;
    font-weight: 600;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.Modal-head--left {
    flex: 1;
}

.Modal-close {
    -webkit-box-ordinal-group: 100;
    order: 99;
    margin-left: auto;
    color: #ffffff;
}

    .Modal-close.is-active,
    .Modal-close:hover,
    .Modal-close:active,
    .Modal-close:focus {
        color: #ffffff;
    }

.Modal-footer {
    margin-top: 30px;
}

@media (max-width: 0px) {
    .Modal-footer .Button {
        min-width: 60%;
    }
}

@media (max-width: 0px) {
    .Modal-footer .Button + .Button {
        margin-top: 15px;
    }
}

@media print, (min-width: 0.01px) {
    .Modal-footer .Button + .Button {
        margin-left: 30px;
    }
}

.Modal--skinny,
.Modal--notification {
    max-width: 64rem;
}

.Modal--medium {
    max-width: 80rem;
}

@media print, (min-width: 0.01px) {
    .Modal--skinny,
    .Modal--notification {
        width: calc(2 / 3 * 100%);
    }

    .Modal--medium {
        width: calc(4 / 5 * 100%);
    }
}

@media (min-width: 1280px) {
    .Modal--skinny,
    .Modal--notification {
        width: 50%;
    }

    .Modal--medium {
        width: 80%;
    }
}

.Modal--notification {
    text-align: center;
}

.Modal--loader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
}

.NLostChart-title {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.14286rem;
}

.Nav {
    display: -webkit-box;
    display: flex;
    height: 60px;
}

.Nav-item {
    display: -webkit-box;
    display: flex;
}

    .Nav-item .a,
    .Nav-item a {
        text-decoration: none;
        color: #ffffff;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        white-space: nowrap;
        font-size: 1.14286rem;
        padding: 0 10px 0 10px;
    }

        .Nav-item a.is-active,
        .Nav-item a:hover,
        .Nav-item a:active,
        .Nav-item a:focus,
        .Nav-item .a.is-active,
        .Nav-item .a:hover,
        .Nav-item .a:active,
        .Nav-item .a:focus {
            background-color: #509bc2;
        }

        .Nav-item .a:before,
        .Nav-item a:before {
            font-size: 2rem;
        }

@media print, (min-width: 0.01px) {
    .Nav-item .a:before,
    .Nav-item a:before {
        margin-right: 10px;
    }
}

@media (max-width: 860px) {
    .Nav-item .a:before,
    .Nav-item a:before {
        margin-right: 0 !important;
    }
}

.NCEIndicator {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

    .NCEIndicator::before {
        /*content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #e5e9e7;*/
    }

    .NCEIndicator div {
        position: relative;
        margin-left: 5px;
        border-width: 3px;
        border-style: solid;
        width: 35px;
        height: 35px;
        border-radius: 17.5px;
        flex-shrink: 0;
    }

    .NCEIndicator .is-healthy {
        border-color: rgba(68, 170, 108, 0.5);
        color: #44aa6c;
    }

        .NCEIndicator .is-healthy circle {
            stroke: #44aa6c;
        }

    .NCEIndicator .is-neutral {
        border-color: rgba(227, 145, 83, 0.5);
        color: #e39153;
    }

        .NCEIndicator .is-neutral circle {
            stroke: #e39153;
        }

    .NCEIndicator .is-unhealthy {
        border-color: rgba(151, 59, 54, 0.5);
        color: #973b36;
    }

        .NCEIndicator .is-unhealthy circle {
            stroke: #973b36;
        }

.NCEIndicator-path {
    position: absolute;
    width: 35px;
    height: 35px;
    top: -3px;
    left: -3px;
}

    .NCEIndicator-path circle {
        transition: stroke-dashoffset 0.5s;
        stroke-dasharray: 100.53096;
        stroke-dashoffset: 100.53096;
        -webkit-transform-origin: center;
        transform-origin: center;
        -webkit-transform: rotateZ(270deg);
        transform: rotateZ(270deg);
    }

.NutrientSummary,
.NutrientsLost {
    display: -webkit-box;
    display: flex;
}

@media (max-width: 1279.99px) {
    .NutrientsLost--sm {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        margin-top: -3px;
        margin-bottom: -3px;
    }
}

.NutrientSummary-type,
.NutrientsLost-type {
    white-space: nowrap;
}

.NutrientsLost--sm .NutrientsLost-type {
    margin-left: 5px;
}

.NutrientSummary-value,
.NutrientsLost-indicator {
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    flex-shrink: 0;
    border-radius: 3px;
    color: #ffffff;
}

.NutrientsLost--sm .NutrientsLost-indicator {
    min-width: 27px;
    height: 27px;
}

.NutrientSummary--lg .NutrientSummary-value,
.NutrientsLost--lg .NutrientsLost-indicator {
    min-width: 25px;
    height: 25px;
}

@media print, (min-width: 0.01px) {
    .NutrientsLost--lg .NutrientsLost-indicator {
        min-width: 25px;
        height: 25px;
    }
}

.NutrientsLost-indicator.is-healthy {
    background-color: #44aa6c;
}

.NutrientsLost-indicator.is-neutral {
    background-color: #e39153;
}

.NutrientsLost-indicator.is-unhealthy {
    background-color: #973b36;
}

.NutrientSummary-item,
.NutrientsLost-n,
.NutrientsLost-ghg,
.NutrientsLost-p {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

    .NutrientSummary-item + .NutrientSummary-item {
        padding-left: 10px;
    }

@media (max-width: 1279.99px) {
    .NutrientsLost--sm .NutrientsLost-n,
    .NutrientsLost--sm .NutrientsLost-p,
    .NutrientsLost--sm .NutrientsLost-ghg,
    .NutrientsLost--sm .NutrientsLost-value {
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

.NutrientsLost--sm .NutrientsLost-p,
.NutrientsLost--sm .NutrientsLost-ghg {
    margin-top: 1px;
}

@media (max-width: 1279.99px) {
    .NutrientsLost--sm .NutrientsLost-p,
    .NutrientsLost--sm .NutrientsLost-ghg {
        padding-left: 0;
    }
}

.Overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 20;
}

.PageHead {
    border-top: 1px solid #f8f9f8;
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075);
}

    .PageHead > .Container {
        min-height: 60px;
        display: -webkit-box;
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;
    }

@media (max-width: 0px) {
    .PageHead > .Container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

@media print, (min-width: 0.01px) {
    .PageHead > .Container {
        -webkit-box-align: center;
        align-items: center;
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

.PageHead .FarmTitle {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.PageHead-searchInput {
    -webkit-box-flex: 1;
    flex-grow: 1;
    border-left: 1px solid #e5e9e7;
}

    .PageHead-searchInput .Field {
        margin-top: 0;
    }

.PageHead-budgetOverview {
    max-width: 40%;
    margin-left: auto;
}

@media (min-width: 1280px) {
    .PageHead-budgetOverview {
        max-width: 50%;
    }
}

.PageHead-budgetOverview span {
    position: relative;
    white-space: nowrap;
}

    .PageHead-budgetOverview span::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #e5e9e7;
    }

@media print, (min-width: 0.01px) {
    .PageHead-actions {
        margin-left: auto;
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        justify-content: flex-end;
    }
}

@media (max-width: 0px) {
    .PageHead-actions {
        margin-top: 15px;
    }
}

@media print, (min-width: 0.01px) {
    .PageHead-actions {
        -webkit-box-align: center;
        align-items: center;
    }
}

.RoundButton {
    flex-shrink: 0;
}

    .RoundButton:before {
        display: -webkit-inline-box;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.075), inset 0 0 0 1px #e2e4e3;
        background-color: #ffffff;
        color: #509bc2;
    }

    .RoundButton.is-active:before,
    .RoundButton:hover:before,
    .RoundButton:active:before,
    .RoundButton:focus:before {
        color: #364052;
        box-shadow: inset 0 0 0 1px #e2e4e3;
    }

.RoundButton--tertiary:before {
    color: #ca5e0c !important;
}

.RoundButton {
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

    .RoundButton[data-target-collapsed="true"] {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    .RoundButton[data-target-collapsed="false"] {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .RoundButton.is-disabled {
        pointer-events: none;
        opacity: 0.4;
    }

.SectionTabs {
    display: -webkit-box;
    display: flex;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25), inset -1px 0 0 0 #d0d6d2;
    background-color: #364052;
    height: 45px;
}

@media (min-width: 1280px) {
    .SectionTabs {
        height: 65px;
    }
}

.SectionTabs-item {
    max-width: 175px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    background-color: #364052;
}

@media print, (min-width: 0.01px) {
    .SectionTabs-item {
        min-width: 55px;
    }
}

.SectionTabs-item .a,
.SectionTabs-item a {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
    color: #8d9590;
    white-space: nowrap;
    text-decoration: none;
}

    .SectionTabs-item a.is-active:before,
    .SectionTabs-item a:hover:before,
    .SectionTabs-item a:active:before,
    .SectionTabs-item a:focus:before {
        color: #ffffff;
    }

    .SectionTabs-item a:before {
        color: #bec8c2;
        display: block;
        font-size: 3.21429rem;
    }

@media (max-width: 0px) {
    .SectionTabs-item a:before {
        font-size: 1.71429rem;
    }
}

.SectionTabs-item.is-active .a,
.SectionTabs-item.is-active a {
    color: #ffffff;
    background-color: #509bc2;
}

.SectionTabs-item.is-reports .a,
.SectionTabs-item.is-reports a {
    color: #ffffff;
    background-color: #36975c;
}

.SectionTabs-item.is-reports-inactive .a,
.SectionTabs-item.is-reports-inactive a {
    color: #000000;
    background-color: #8d9590;
}

.SectionTabs-item.is-active a:before {
    color: inherit;
}

.SectionTabs-item.is-active a::after {
    position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    top: calc(100% - 1px);
    left: calc(50% - 12.72792px / 2);
    background-color: #509bc2;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.SectionTabs-item .Todo {
    position: absolute;
    top: 5px;
    left: 5px;
}

.SectionTabs-item-name {
    color: white;
    z-index: 10;
    position: relative;
    top: -5px;
    display: none;
}

@media (min-width: 1280px) {
    .SectionTabs-item-name {
        display: block;
    }
}

.ColourPanelSmall {
    min-width: 140px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 0 10px 5px 10px;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    position: relative;
}

.ColourPanel {
    min-width: 280px;
    margin: 15px;
    margin-bottom: 0;
    padding: 20px;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    position: relative;
}

.ColourPanel h5 {
    margin-top: 0;
}

.ColourPanel--nloss {
    border-color: #44aa6c;
    background-color: rgba(68, 170, 108, 0.1);
}

.ColourPanel--nloss::before {
    background-color: #44aa6c;
}

.ColourPanel--ploss {
    border-color: #e39153;
    background-color: rgba(227, 145, 83, 0.1);
}

.ColourPanel--ploss::before {
    background-color: #e39153;
}

.ColourPanel--ghg {
    border-color: #4285f4;
    background-color: rgba(66, 133, 244, 0.1);
}

.ColourPanel--ghg::before {
    background-color: #4285f4;
}

[class*="ColourPanel--"] {
    position: relative;
}

[class*="ColourPanel--"]::before {
    content: "";
    position: absolute;
    width: 12px;
    left: -1px;
    top: -1px;
    bottom: -1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}



.ColourPanelModern {
    min-width: 280px;
    margin: 15px;
    margin-bottom: 0;
    padding: 15px;
    position: relative;
}

.ColourPanelModern h5 {
    margin-top: 0;
}

.ColourPanelModern--nloss {
    border-color: #44aa6c;
    background-color: rgba(68, 170, 108, 0.1);
}

.ColourPanelModern--nloss::before {
    background-color: #44aa6c;
}

.ColourPanelModern--ploss {
    border-color: #e39153;
    background-color: rgba(227, 145, 83, 0.1);
}

.ColourPanelModern--ploss::before {
    background-color: #e39153;
}

.ColourPanelModern--ghg {
    border-color: #4285f4;
    background-color: rgba(66, 133, 244, 0.1);
}

.ColourPanelModern--ghg::before {
    background-color: #4285f4;
}

.ColourPanelModern--selected {
    border-color: #fff;
    background-color: #fff;
}

.ColourPanelModern--selected::before {
    background-color: #4285f4;
}

[class*="ColourPanelModern--"] {
    position: relative;
}

[class*="ColourPanelModern--"]::before {
    content: "";
    position: absolute;
    width: 4px;
    left: -1px;
    top: 0px;
    bottom: 0px;
}

.ManualSoil {
    padding: 7.5px 25px;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    text-align: center;
    height: 70px;
    width: 220px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #fff;
}

.ManualSoil-name-parent {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
    width: 185px;
}

    .ManualSoil-name-parent .icon-soil {
        position: absolute;
        top: 5px;
    }

.ManualSoil-name {
    font-weight: 600;
    color: #509bc2;
    font-size: 1.14286rem;
    white-space: nowrap;
}

.ManualSoil.ManualSoil--add {
    border: 2px dashed #bec8c2;
    background-color: #f8f9f8;
}

.ManualSoil.ManualSoil--error {
    outline: none;
    box-shadow: 0 0 10px #ff4c4c;
    border: none;
    background-color: #ffe5e5;
}

.SoilRange {
    margin-top: 15px;
}

.SoilRange-item {
    max-width: 240px;
    width: 100%;
    min-height: 155px;
    margin-left: auto;
    margin-right: auto;
    padding: 7.5px 15px;
    border: 1px solid #bec8c2;
    border-radius: 5px;
    position: relative;
}

    .SoilRange-item.SoilRange-item--add {
        text-align: center;
        border: 2px dashed #8d9590;
    }

        .SoilRange-item.SoilRange-item--add img {
            margin-top: 7.5px;
            margin-bottom: -7.5px;
        }

.SoilRange-delete {
    position: absolute;
    top: 5px;
    right: 0;
}

.ManualSoilRange-value {
    color: #666666;
    font-size: 1.54286rem;
    font-weight: 500;
    line-height: 1.31818;
}

    .ManualSoilRange-value span {
        font-size: 1.12857rem;
    }

.SoilRange-value {
    color: #666666;
    font-size: 3.14286rem;
    line-height: 1.31818;
}

    .SoilRange-value span {
        font-size: 1.42857rem;
    }

.SoilRange-name {
    font-weight: 600;
    color: #509bc2;
    font-size: 1.14286rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: normal;
    padding-right: 15px;
}

.SoilRange-slider {
    margin: 40px 40px 20px;
}

.ManualSoil-item--midGrey::before {
    background-color: #bec8c2;
}

.ManualSoil-item--yellow::before {
    background-color: #ffba49;
}

.ManualSoil-item--orange::before {
    background-color: #f87666;
}

.ManualSoil-item--tan::before {
    background-color: #e39153;
}

.ManualSoil-item--red::before {
    background-color: #ad343e;
}

.ManualSoil-item--brick::before {
    background-color: #973b36;
}

.ManualSoil-item--magenta::before {
    background-color: #c84273;
}

.ManualSoil-item--grape::before {
    background-color: #8f5687;
}

.ManualSoil-item--purple::before {
    background-color: #7d63a5;
}

.ManualSoil-item--navy::before {
    background-color: #4c576c;
}

.ManualSoil-item--blue::before {
    background-color: #395998;
}

.ManualSoil-item--sky::before {
    background-color: #509bc2;
}

.ManualSoil-item--grey::before {
    background-color: #e9e9e9;
}

[class*="ManualSoil-item--"] {
    position: relative;
}

    [class*="ManualSoil-item--"]::before {
        content: "";
        position: absolute;
        width: 6px;
        left: -1px;
        top: -1px;
        bottom: -1px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

.SoilRange-item--midGrey::before {
    background-color: #bec8c2;
}

.SoilRange-item--yellow::before {
    background-color: #ffba49;
}

.SoilRange-item--orange::before {
    background-color: #f87666;
}

.SoilRange-item--tan::before {
    background-color: #e39153;
}

.SoilRange-item--red::before {
    background-color: #ad343e;
}

.SoilRange-item--brick::before {
    background-color: #973b36;
}

.SoilRange-item--magenta::before {
    background-color: #c84273;
}

.SoilRange-item--grape::before {
    background-color: #8f5687;
}

.SoilRange-item--purple::before {
    background-color: #7d63a5;
}

.SoilRange-item--navy::before {
    background-color: #4c576c;
}

.SoilRange-item--blue::before {
    background-color: #395998;
}

.SoilRange-item--sky::before {
    background-color: #509bc2;
}

.SoilRange-item--grey::before {
    background-color: #e9e9e9;
}

[class*="SoilRange-item--"] {
    position: relative;
}

    [class*="SoilRange-item--"]::before {
        content: "";
        position: absolute;
        width: 6px;
        left: -1px;
        top: -1px;
        bottom: -1px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

.SubMenu {
    border-bottom: 1px solid #e5e9e7;
    display: -webkit-box;
    background-color: #fff;
}

.SubMenu-item {
    display: -webkit-box;
    display: inline-block;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    padding: 5px 5px 5px 5px;
}

    .SubMenu-item .a,
    .SubMenu-item a {
        padding: 7.5px 20px 7.5px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        color: #58585a;
        white-space: nowrap;
        text-decoration: none;
        display: inline-block;
    }

        .SubMenu-item a.is-active,
        .SubMenu-item a:hover,
        .SubMenu-item a:active,
        .SubMenu-item a:focus,
        .SubMenu-item .a.is-active,
        .SubMenu-item .a:hover,
        .SubMenu-item .a:active,
        .SubMenu-item .a:focus {
            box-shadow: inset 0 -3px 0 0 #4591b9;
            color: #58585a;
        }

    .SubMenu-item.is-active .a,
    .SubMenu-item.is-active a {
        box-shadow: inset 0 -3px 0 0 #4591b9;
        color: #58585a;
    }

.SubTabs {
    border-bottom: 1px solid #e5e9e7;
    display: -webkit-box;
    display: flex;
}

.SubTabs-item {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
}

    .SubTabs-item .a,
    .SubTabs-item a {
        padding-left: 60px;
        padding-right: 60px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        color: #58585a;
        white-space: nowrap;
        text-decoration: none;
    }

        .SubTabs-item a.is-active,
        .SubTabs-item a:hover,
        .SubTabs-item a:active,
        .SubTabs-item a:focus {
            box-shadow: 0 2px 0 0 #4591b9;
        }

    .SubTabs-item.is-active .a,
    .SubTabs-item.is-active a {
        box-shadow: 0 2px 0 0 #4591b9;
    }

        .SubTabs-item.is-active a::after {
            position: absolute;
            content: "";
            width: 9px;
            height: 9px;
            top: calc(100% - 1px);
            left: calc(50% - 12.72792px / 2);
            background-color: #ffffff;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            box-shadow: -2px 2px 0 0 #4591b9;
        }

.SubTabs--center {
    -webkit-box-pack: center;
    justify-content: center;
}

.Table {
    border: 1px solid #e5e9e7;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
    border-radius: 5px;
}

    .Table + .Table {
        margin-top: 30px;
    }

    .Table table {
        width: 100%;
        border-collapse: collapse;
        page-break-inside: auto;
    }

    .Table th,
    .Table td {
        border-width: 0;
        padding-top: 9px;
        padding-bottom: 8px;
        border-style: solid;
    }

    .Table .th,
    .Table th {
        white-space: nowrap;
        text-align: left;
        background-color: #f8f9f8;
        border-color: #e5e9e7 #bec8c2;
    }

        .Table .th:not(:first-child),
        .Table th:not(:first-child) {
            border-left-width: 1px;
            border-left-color: #e5e9e7;
        }

    .Table thead:not(:first-child) th {
        border-top-width: 1px;
    }

    .Table .th:empty::before,
    .Table th:empty::before {
        content: "\00a0";
    }

    .Table td {
        border-top-width: 1px;
        border-color: #e5e9e7;
    }

        .Table td:not(:first-child) {
            border-left-width: 1px;
        }

@media print {
    .Table {
        box-shadow: none;
        padding-top: 0;
        padding-left: 0;
    }
}

thead tr:not(:first-child) th {
    border-top-width: 1px;
    border-color: #e5e9e7 #bec8c2;
}

tfoot tr {
    border-width: 1px;
    border-color: #e5e9e7 #bec8c2;
    background-color: #f8f9f8;
}

tfoot tr {
    border-width: 1px;
    border-color: #e5e9e7 #bec8c2;
    background-color: #f8f9f8;
    font-weight: 600;
}

th.is-selected,
td.is-selected,
tr.is-selected td,
tr.tr-nested td {
    background-color: rgba(80, 155, 194, 0.1);
}

.Table tr.tr-nested td:first-child {
    padding-left: 30px;
}

.Table-Compressed tr.tr-nested td:first-child {
    padding-left: 20px !important;
}

tr.tr-graph td,
tr.tr-legend td {
    background-color: #f8f9f8;
}

    tr.tr-graph td:nth-child(2n + 3) {
        background-color: #ffffff;
    }

tr.hover.is-active,
tr.hover:hover,
tr.hover:active,
tr.hover:focus,
div.Search-result:hover,
a:focus > div.Search-result {
    background-color: rgba(80, 155, 194, 0.1);
}

.td--keyValuePair {
    max-width: 190px;
    text-align: left;
}

    .td--keyValuePair.td--keyValuePair {
        padding-top: 7px;
        padding-bottom: 7px;
    }

.td-value {
    margin-top: 1px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

    .td-value .icon {
        position: relative;
        top: -3px;
        margin-bottom: -5px;
    }

.th--shrink,
.td--shrink {
    width: 0.1%;
}

.Table-tr--color > td:first-child::before,
.Table-tr--color > th:first-child::before {
    background-color: var(--row-color);
}

.Colour-td--nloss::before,
.Table-tr--nloss > td:first-child::before {
    background-color: #44aa6c;
}

.Colour-td--ploss::before,
.Table-tr--ploss > td:first-child::before {
    background-color: #e39153;
}

.Table-tr--kloss > td:first-child::before,
.Table-tr--NonBlock > td:first-child::before {
    background-color: #ccc;
}

.Table-tr--ghg > td:first-child::before {
    background-color: #4285f4;
}

.Table-tr--ProductiveCrop > td:first-child::before {
    background-color: #c0be38;
}

.Table-tr--sloss > td:first-child::before,
.Table-tr--FodderCrop > td:first-child::before {
    background-color: #9b8233;
}

.Table-tr--ProductiveFruit > td:first-child::before {
    background-color: #ad343e;
}

.Table-tr--ProductivePasture > td:first-child::before {
    background-color: #65925f;
}

.Table-tr--CutAndCarry > td:first-child::before {
    background-color: #d6905a;
}

.Table-tr--ProductiveOutdoorPigs > td:first-child::before {
    background-color: #b961a6;
}

.Table-tr--NonProductiveHouse > td:first-child::before {
    background-color: #384766;
}

.Table-tr--NonProductiveTreesAndScrub > td:first-child::before {
    background-color: #2c3b29;
}

.Table-tr--NonProductiveWetland > td:first-child::before {
    background-color: #2a58d4;
}

.Table-tr--NonProductiveRiparian > td:first-child::before {
    background-color: #2ac0d4;
}

.Table-tr--yellow > td:first-child::before {
    background-color: #ffba49;
}

.Table-tr--yellow.is-selected td {
    background-color: #fff7eb;
}

.Table-tr--orange > td:first-child::before {
    background-color: #f87666;
}

.Table-tr--orange.is-selected td {
    background-color: #feeeeb;
}

.Table-tr--tan > td:first-child::before {
    background-color: #e39153;
}

.Table-tr--tan.is-selected td {
    background-color: #fcf4ed;
}

.Table-tr--green > td:first-child::before {
    background-color: #1bb164;
}

.Table-tr--red > td:first-child::before {
    background-color: #ad343e;
}

.Table-tr--red.is-selected td {
    background-color: #faeff0;
}

.Table-tr--brick > td:first-child::before {
    background-color: #973b36;
}

.Table-tr--brick.is-selected td {
    background-color: #faf0f0;
}

.Table-tr--magenta > td:first-child::before {
    background-color: #c84273;
}

.Table-tr--magenta.is-selected td {
    background-color: #faeff3;
}

.Table-tr--grape > td:first-child::before {
    background-color: #8f5687;
}

.Table-tr--grape.is-selected td {
    background-color: #f7f2f7;
}

.Table-tr--purple > td:first-child::before {
    background-color: #7d63a5;
}

.Table-tr--purple.is-selected td {
    background-color: #f4f2f8;
}

.Table-tr--navy > td:first-child::before {
    background-color: #4c576c;
}

.Table-tr--navy.is-selected td {
    background-color: #f3f4f7;
}

.Table-tr--blue > td:first-child::before {
    background-color: #395998;
}

.Table-tr--blue.is-selected td {
    background-color: #f0f3f9;
}

.Colour-td--sky::before,
.Table-tr--sky > td:first-child::before {
    background-color: #509bc2;
}

.Table-tr--sky.is-selected td {
    background-color: #f0f6fa;
}

[class*="Colour-td--"] {
    padding-left: 10px !important;
}

[class*="Colour-td--"],
[class*="Table-tr--"] > td:first-child,
[class*="Table-tr--"] > th:first-child {
    position: relative;
}

    [class*="Colour-td--"]::before,
    [class*="Table-tr--"] > td:first-child::before,
    [class*="Table-tr--"] > th:first-child::before {
        content: "";
        position: absolute;
        width: 6px;
        left: -1px;
        top: 0;
        bottom: -1px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

.Table-tr--middle > td:first-child::before,
.Table-tr--start > td:first-child::before,
.Colour-td--middle::before,
.Colour-td--start::before {
    border-bottom-left-radius: 0 !important;
}

.Table-tr--middle > td:first-child::before,
.Table-tr--end > td:first-child::before,
.Colour-td--middle::before,
.Colour-td--end::before {
    border-top-left-radius: 0 !important;
}

.th--sortable {
    position: relative;
}

    .th--sortable.th--sortable {
        padding-right: 40px;
    }

    .th--sortable .a,
    .th--sortable a {
        display: block;
        color: #58585a;
    }

        .th--sortable a.is-active,
        .th--sortable a:hover,
        .th--sortable a:active,
        .th--sortable a:focus {
            color: #509bc2;
        }

    .th--sortable i {
        position: absolute;
        top: 5px;
        right: 9px;
    }

@media (min-width: 1280px) {
    .th--sortable i {
        right: 14px;
    }
}

.th--sortable.is-sorted i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.tr--white th {
    background-color: #ffffff;
}

.tr--joinTop td {
    border-top-width: 0;
}

.td--alignTop {
    vertical-align: top;
}

.td--alignBottom {
    vertical-align: bottom;
}

.td--total {
    background-color: #58585a;
    color: #ffffff;
    font-weight: 600;
}

    .td--total.has-error {
        color: #973b36;
        background-color: #f8edec;
    }

td.td-labelled {
    position: relative;
    padding-top: 30px;
}

    td.td-labelled .td-label {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        border-radius: 0 0 3px 0;
        line-height: 30px;
    }

.Tile + .Tile {
    margin-top: 20px;
}

@media (min-width: 1280px) {
    .Tile + .Tile {
        border-width: 0;
        margin-top: 30px;
    }
}

.Map-info,
.Tile {
    cursor: default;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.075);
    border-radius: 5px;
    background-color: #ffffff;
}

.Tile-head {
    background-color: #364052;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 600;
    color: #ffffff;
}

.Tile-head--closed {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Tile-head--super {
    background-color: #566a89;
}

.Tile-head--secondary {
    background-color: #509bc2;
}

.Tile-head-right {
    margin-left: auto;
}

.Tile-head-close {
    margin-left: auto;
    -webkit-box-ordinal-group: 100;
    order: 99;
    text-decoration: none;
    color: #ffffff;
}

    .Tile-head-close.is-active,
    .Tile-head-close:hover,
    .Tile-head-close:active,
    .Tile-head-close:focus {
        color: #ffffff;
    }

.Tile-title {
    min-height: 50px;
    font-size: 1.14286rem;
    color: #ffffff;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #364052;
}

.Tile-title--large {
    font-size: 1.28571rem;
}

.Tile-body {
    position: relative;
}

form .Tile-body {
    padding-top: 0;
}

    form .Tile-body .Table {
        margin-top: 15px;
    }

.Tile-body-message {
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 45px 15px 75px;
}

@media print, (min-width: 0.01px) {
    .Tile-body-message {
        margin: 90px 0 120px 20%;
        width: 60%;
    }
}

@media (min-width: 1280px) {
    .Tile-body-message {
        width: 50%;
        margin-left: 25%;
    }
}

.Table .Tile-body-message {
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}

.Tile-body-message-icon.is-disabled {
    opacity: 0.3;
}

.Todo {
    position: relative;
    height: 24px;
    width: 24px;
}

    .Todo::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 6px;
        display: block;
        background-color: #ffffff;
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
    }

    .Todo .icon {
        position: relative;
        z-index: 1;
    }

.Todo--error {
    color: #973b36;
}

.Todo--warning {
    color: #e39153;
}

.UtilityNav {
    font-size: 0.92857rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-left: auto;
}

.UtilityNav-user {
    white-space: nowrap;
    position: relative;
    text-decoration: none;
    padding-left: 15px;
    padding-right: 5px;
    transition: background-color 0.2s;
}

    .UtilityNav-user.is-open {
        background-color: #4c576c;
    }

        .UtilityNav-user.is-open .UtilityNav-user-affordance {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .UtilityNav-user.is-open .UtilityNav-user-dropdown {
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.075);
            max-height: 400vh;
            padding-top: 7.5px;
            padding-bottom: 7.5px;
        }

.UtilityNav-user-affordance {
    margin-left: 5px;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.UtilityNav-user-token {
    font-size: 0.95rem;
    letter-spacing: 1px;
    flex-shrink: 0;
    background-color: #ffffff;
    color: #364052;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .UtilityNav-user-token {
        font-size: 0.89286rem;
    }
}

@media print, (min-width: 0.01px) {
    .UtilityNav-user-token {
        margin-right: 10px;
    }
}

.UtilityNav-user-name {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #ffffff;
}

@media (max-width: 0px) {
    .UtilityNav-user-name {
        display: none;
    }
}

.UtilityNav-user-trigger {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 60px;
}

.UtilityNav-user-dropdown {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    z-index: 30;
    background-color: #4c576c;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 100%;
}

    .UtilityNav-user-dropdown > li > .a,
    .UtilityNav-user-dropdown > li > a {
        white-space: nowrap;
        color: #ffffff;
        padding: 7.5px 15px;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
    }

        .UtilityNav-user-dropdown > li > a.is-active,
        .UtilityNav-user-dropdown > li > a:hover,
        .UtilityNav-user-dropdown > li > a:active,
        .UtilityNav-user-dropdown > li > a:focus,
        .UtilityNav-user-dropdown > li > .a.is-active,
        .UtilityNav-user-dropdown > li > .a:hover,
        .UtilityNav-user-dropdown > li > .a:active,
        .UtilityNav-user-dropdown > li > .a:focus {
            background-color: #364052;
        }

    .UtilityNav-user-dropdown .icon {
        margin-right: 7.5px;
    }

.UtilityNav-divider {
    background: #65789b;
    margin: 5px 2px;
}

.u-flex {
    display: -webkit-box !important;
    display: flex !important;
}

.u-flexWrap {
    flex-wrap: wrap;
}

.u-flexColumn {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
}

.u-flexJustifyCenter {
    -webkit-box-pack: center !important;
    justify-content: center !important;
}

.u-flexAlignItemsCenter {
    -webkit-box-align: center !important;
    align-items: center !important;
}

.u-flexAlignItemsEnd {
    -webkit-box-align: end !important;
    align-items: end !important;
}

.u-flexJustifyBetween {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
}

.u-flexJustifyEnd {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
}

.u-flexStart {
    align-items: flex-start;
}

.u-flexSelfStart {
    align-self: flex-start;
}

.u-flexCenter {
    align-self: center;
}

.u-flexEnd {
    align-items: flex-end;
}

.u-flexBreak-sm {
    width: 100% !important;
    padding: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
}

.u-flex-wrap {
    flex-wrap: wrap;
}

.u-flex-grow {
    flex-grow: 1;
}

.u-flex-growBig {
    flex-grow: 2;
}

@media (min-width: 1280px) {
    .u-flexBreak-sm {
        display: none !important;
    }
}

.u-block {
    display: block !important;
}

.u-hidden {
    display: none !important;
}

.u-sm-hidden {
    display: none;
}

@media (min-width: 1024px) {
    .u-sm-hidden {
        display: flex !important;
    }
}

@media (max-width: 1280px) {
    .u-xl-hidden {
        display: none !important;
    }
}

.u-floatRight {
    float: right !important;
}

.u-heightFull {
    height: 100% !important;
}

@media print, (min-width: 0.01px) {
    .u-md-heightFull {
        height: 100% !important;
    }
}

@media (min-width: 1024px) {
    .u-lg-heightFull {
        height: 100% !important;
    }
}

.u-mt-0 {
    margin-top: 0 !important;
}

.u-mt-xs {
    margin-top: 5px !important;
}

.u-mt-sm {
    margin-top: 7.5px !important;
}

.u-mt-md {
    margin-top: 15px !important;
}

.u-mt-lg {
    margin-top: 30px !important;
}

.u-mt-xl {
    margin-top: 45px !important;
}

.u-mt-xxl {
    margin-top: 60px !important;
}

.u-ml-0 {
    margin-left: 0 !important;
}

.u-ml-xs {
    margin-left: 5px !important;
}

.u-ml-sm {
    margin-left: 7.5px !important;
}

.u-ml-md {
    margin-left: 15px !important;
}

.u-ml-lg {
    margin-left: 30px !important;
}

.u-ml-xxl {
    margin-left: 60px !important;
}

.u-mb-0 {
    margin-bottom: 0 !important;
}

.u-mb-sm {
    margin-bottom: 7.5px !important;
}

.u-mb-md {
    margin-bottom: 15px !important;
}

.u-mb-lg {
    margin-bottom: 30px !important;
}

.u-mb-xl {
    margin-bottom: 40px !important;
}

.u-ml-auto {
    margin-left: auto !important;
}

.u-mr-0 {
    margin-right: 0 !important;
}

.u-mr-xs {
    margin-right: 5px !important;
}

.u-mr-sm {
    margin-right: 7.5px !important;
}

.u-mr-md {
    margin-right: 15px !important;
}

.u-mr-lg {
    margin-right: 20px !important;
}

.u-mr-xl {
    margin-right: 30px !important;
}

@media (max-width: 0px) {
    .u-sm-mt-0 {
        margin-top: 0 !important;
    }

    .u-sm-mt-sm {
        margin-top: 7.5px !important;
    }

    .u-sm-mt-md {
        margin-top: 15px !important;
    }

    .u-sm-mt-lg {
        margin-top: 30px !important;
    }

    .u-sm-mt-xl {
        margin-top: 45px !important;
    }

    .u-sm-mt-xxl {
        margin-top: 60px !important;
    }

    .u-sm-mb-0 {
        margin-bottom: 0 !important;
    }

    .u-sm-mb-sm {
        margin-bottom: 7.5px !important;
    }

    .u-sm-mb-md {
        margin-bottom: 15px !important;
    }

    .u-sm-mb-lg {
        margin-bottom: 30px !important;
    }

    .u-sm-ml-auto {
        margin-left: auto !important;
    }

    .u-sm-ml-sm {
        margin-left: 15px !important;
    }

    .u-sm-ml-md {
        margin-left: 30px !important;
    }

    .u-sm-mr-xs {
        margin-right: 7.5px !important;
    }

    .u-sm-mr-sm {
        margin-right: 15px !important;
    }

    .u-sm-mr-md {
        margin-right: 30px !important;
    }
}

@media print, (min-width: 0.01px) {
    .u-md-mt-0 {
        margin-top: 0 !important;
    }

    .u-md-mt-sm {
        margin-top: 7.5px !important;
    }

    .u-md-mt-md {
        margin-top: 15px !important;
    }

    .u-md-mt-lg {
        margin-top: 30px !important;
    }

    .u-md-mt-xl {
        margin-top: 45px !important;
    }

    .u-md-mt-xxl {
        margin-top: 60px !important;
    }

    .u-md-mb-0 {
        margin-bottom: 0 !important;
    }

    .u-md-mb-sm {
        margin-bottom: 7.5px !important;
    }

    .u-md-mb-md {
        margin-bottom: 15px !important;
    }

    .u-md-mb-lg {
        margin-bottom: 30px !important;
    }

    .u-md-ml-auto {
        margin-left: auto !important;
    }

    .u-md-ml-sm {
        margin-left: 15px !important;
    }

    .u-md-ml-md {
        margin-left: 30px !important;
    }

    .u-md-mr-xs {
        margin-right: 7.5px !important;
    }

    .u-md-mr-sm {
        margin-right: 15px !important;
    }

    .u-md-mr-md {
        margin-right: 30px !important;
    }
}

.u-p-0 {
    padding: 0 !important;
}

.u-p-5 {
    padding: 5px !important;
}

.u-p-10 {
    padding: 10px !important;
}

.u-p-20 {
    padding: 20px !important;
}

.u-p-xxs {
    padding: 3px !important;
}

.u-p-md {
    padding: 15px !important;
}

.u-pb-xxs {
    padding-bottom: 3px !important;
}

.u-pb-xs {
    padding-bottom: 5px !important;
}

.u-pb-sm {
    padding-bottom: 7.5px !important;
}

.u-pb-md {
    padding-bottom: 15px !important;
}

.u-pb-lg {
    padding-bottom: 20px !important;
}

.u-pb-xl {
    padding-bottom: 45px !important;
}

.u-pb-0 {
    padding-bottom: 0 !important;
}

.u-pt-0 {
    padding-top: 0 !important;
}

.u-pt-xxs {
    padding-top: 3px !important;
}

.u-pt-xs {
    padding-top: 5px !important;
}

.u-pt-sm {
    padding-top: 7.5px !important;
}

.u-pt-md {
    padding-top: 15px !important;
}

.u-pt-lg {
    padding-top: 20px !important;
}

.u-pt-xl {
    padding-top: 25px !important;
}

.u-pt-xxl {
    padding-top: 30px !important;
}

.u-pr-0 {
    padding-right: 0 !important;
}

.u-pr-xs {
    padding-right: 5px !important;
}

.u-pr-sm {
    padding-right: 7.5px !important;
}

.u-pr-md {
    padding-right: 15px !important;
}

.u-pr-lg {
    padding-right: 20px !important;
}

.u-pr-xl {
    padding-right: 25px !important;
}

.u-pr-xxl {
    padding-right: 30px !important;
}

.u-pl-0 {
    padding-left: 0 !important;
}

.u-pl-xs {
    padding-left: 5px !important;
}

.u-pl-sm {
    padding-left: 7.5px !important;
}

.u-pl-md {
    padding-left: 15px !important;
}

.u-pl-lg {
    padding-left: 20px !important;
}

.u-pl-xl {
    padding-left: 25px !important;
}

.u-pl-xxl {
    padding-left: 30px !important;
}

@media (max-width: 0px) {
    .u-sm-widthFull {
        width: 100% !important;
    }
}

.u-widthFull {
    width: 100% !important;
}

.u-maxWidthFull {
    max-width: 100% !important;
}

.u-textCenter {
    text-align: center !important;
}

.u-textRight {
    text-align: right !important;
}

.u-textLeft {
    text-align: left !important;
}

.u-textNoWrap {
    white-space: nowrap !important;
}

.u-textUpper {
    text-transform: uppercase;
}

.u-textUnderline {
    text-decoration: underline;
}

.u-textTransformNone {
    text-transform: none !important;
}

.u-opacity-sm {
    opacity: 0.3;
}

.u-bt-1 {
    border-top: 1px solid #e4f5eb;
}

.BlockList-item span,
.FarmTable-item--ellipsis .FarmTable-key,
.FarmTable-item--ellipsis .FarmTable-value-link-text,
.FarmTable-item--ellipsis .FarmTable-value,
.FarmTitle-heading,
.PageHead-budgetOverview,
.td--keyValuePair,
.td-value-text,
.u-textEllipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.u-textLight {
    font-weight: 400 !important;
}

.u-textMedium {
    font-weight: 500 !important;
}

.u-textBold {
    font-weight: 600 !important;
}

.u-textDark {
    color: #58585a !important;
}

.u-textPrimary {
    color: #364052 !important;
}

.u-textSecondary {
    color: #509bc2 !important;
}

.u-textTertiary {
    color: #ca5e0c !important;
}

.u-textCoolBlue {
    color: #566a89 !important;
}

.u-textWhite {
    color: #ffffff !important;
}

.u-textMidGrey {
    color: #bec8c2 !important;
}

.u-textGrey {
    color: #999999 !important;
}

.u-textError {
    color: #973b36 !important;
}

.u-textInfo {
    color: #509bc2 !important;
}

.u-textSuccess {
    color: #36975c !important;
}

.u-text-xxl {
    font-size: 35px !important;
}

.u-text-xl {
    font-size: 1.31429rem !important;
}

.u-text-lg {
    font-size: 1.28571rem !important;
}

.u-text-md {
    font-size: 1.1rem !important;
}

.u-text-sm {
    font-size: 12px !important;
}

.u-textWarning {
    color: #e39153 !important;
}

[class~="u-text"]:before {
    color: inherit;
}

.u-width1of1 {
    width: 100% !important;
}

.u-width1of2 {
    width: 50% !important;
}

.u-width1of3 {
    width: 33.33333% !important;
}

.u-width2of3 {
    width: 66.66667% !important;
}

.u-width1of4 {
    width: 25% !important;
}

.u-width3of4 {
    width: 75% !important;
}

.u-width1of5 {
    width: 20% !important;
}

.u-width2of5 {
    width: 40% !important;
}

.u-width3of5 {
    width: 60% !important;
}

.u-width4of5 {
    width: 80% !important;
}

.u-width1of6 {
    width: 16.66% !important;
}

.u-width2of6 {
    width: 33.33% !important;
}

.u-width1of7 {
    width: 14.28% !important;
}

.u-width1of8 {
    width: 12.5% !important;
}

@media print, (min-width: 0.01px) {
    .u-md-width1of1 {
        width: 100% !important;
    }

    .u-md-width1of2 {
        width: 50% !important;
    }

    .u-md-width1of3 {
        width: 33.33333% !important;
    }

    .u-md-width2of3 {
        width: 66.66667% !important;
    }

    .u-md-width1of4 {
        width: 25% !important;
    }

    .u-md-width3of4 {
        width: 75% !important;
    }

    .u-md-width1of5 {
        width: 20% !important;
    }

    .u-md-width2of5 {
        width: 40% !important;
    }

    .u-md-width3of5 {
        width: 60% !important;
    }

    .u-md-width4of5 {
        width: 80% !important;
    }

    .u-md-width1of6 {
        width: 16.66% !important;
    }

    .u-md-width1of7 {
        width: 14.28% !important;
    }

    .u-md-width1of8 {
        width: 12.5% !important;
    }
}

@media (min-width: 1024px) {
    .u-lg-width1of1 {
        width: 100% !important;
    }

    .u-lg-width1of2 {
        width: 50% !important;
    }

    .u-lg-width1of3 {
        width: 33.33333% !important;
    }

    .u-lg-width2of3 {
        width: 66.66667% !important;
    }

    .u-lg-width1of4 {
        width: 25% !important;
    }

    .u-lg-width3of4 {
        width: 75% !important;
    }

    .u-lg-width1of5 {
        width: 20% !important;
    }

    .u-lg-width2of5 {
        width: 40% !important;
    }

    .u-lg-width3of5 {
        width: 60% !important;
    }

    .u-lg-width4of5 {
        width: 80% !important;
    }

    .u-lg-width1of6 {
        width: 16.66% !important;
    }

    .u-lg-width1of7 {
        width: 14.28% !important;
    }

    .u-lg-width1of8 {
        width: 12.5% !important;
    }
}

@media (min-width: 1280px) {
    .u-xl-width1of1 {
        width: 100% !important;
    }

    .u-xl-width1of2 {
        width: 50% !important;
    }

    .u-xl-width1of3 {
        width: 33.33333% !important;
    }

    .u-xl-width2of3 {
        width: 66.66667% !important;
    }

    .u-xl-width1of4 {
        width: 25% !important;
    }

    .u-xl-width3of4 {
        width: 75% !important;
    }

    .u-xl-width1of5 {
        width: 20% !important;
    }

    .u-xl-width2of5 {
        width: 40% !important;
    }

    .u-xl-width3of5 {
        width: 60% !important;
    }

    .u-xl-width4of5 {
        width: 80% !important;
    }

    .u-xl-width1of6 {
        width: 16.66% !important;
    }

    .u-xl-width1of7 {
        width: 14.28% !important;
    }

    .u-xl-width1of8 {
        width: 12.5% !important;
    }
}

@media (min-width: 1500px) {
    .u-xxl-width1of1 {
        width: 100% !important;
    }

    .u-xxl-width1of2 {
        width: 50% !important;
    }

    .u-xxl-width1of3 {
        width: 33.33333% !important;
    }

    .u-xxl-width2of3 {
        width: 66.66667% !important;
    }

    .u-xxl-width1of4 {
        width: 25% !important;
    }

    .u-xxl-width3of4 {
        width: 75% !important;
    }

    .u-xxl-width1of5 {
        width: 20% !important;
    }

    .u-xxl-width2of5 {
        width: 40% !important;
    }

    .u-xxl-width3of5 {
        width: 60% !important;
    }

    .u-xxl-width4of5 {
        width: 80% !important;
    }

    .u-xxl-width1of6 {
        width: 16.66% !important;
    }

    .u-xxl-width1of7 {
        width: 14.28% !important;
    }

    .u-xxl-width1of8 {
        width: 12.5% !important;
    }
}

@page {
    margin: 0.5cm 0;
    size: A4 portrait;
}

@media print {
    .Header {
        background-color: white;
        -webkit-print-color-adjust: exact;
    }

    .FarmTitle-backLink,
    .ButtonBar {
        display: none !important;
    }

    .RoundButton,
    .RoundButton-up {
        display: none !important;
    }

    .u-print,
    .Header-logo-print {
        display: block !important;
    }

    body {
        background-color: white;
        -webkit-print-color-adjust: exact;
    }

    * {
        border-color: #cecece !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    .Tile-body {
        border: 1px solid #e5e9e7;
    }

    .DescriptionOverview {
        border-top: 1px solid #e5e9e7;
    }

    .SubTabs,
    .DescriptionOverview {
        border-right: 1px solid #e5e9e7;
    }

    .FarmTable,
    .SubTabs,
    .DescriptionOverview {
        border-left: 1px solid #e5e9e7;
    }

    .Select select,
    .Input {
        border-bottom: 1px solid #e5e9e7;
    }

    .Header-logo-print {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .Container {
        max-width: none !important;
    }

    .PageBody {
        padding-top: 22.5px;
    }

    .ActionsBar {
        flex-wrap: wrap;
    }

    .SubTabs {
        border-bottom: none;
    }
}

.u-hint {
    color: #8d9590;
}

:root {
    --page-background-colour: #eee;
    --form-background-colour: #fff;
    --button-colour: #48546a;
    --button-hover-colour: #ccd234;
    --text-colour: #333;
    --faded-text-colour: #777;
    --input-background-colour: #fff;
    --input-placeholder-colour: #333;
}

.paystation-payment-frame {
    width: 100%;
    height: 655px;
    border: none;
    padding-top: 30px;
}

    .paystation-payment-frame.short {
        height: 150px;
    }

    .paystation-payment-frame.tall {
        height: 880px;
    }

.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    line-height: 24px;
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    color: #555;
}

    .invoice-box table {
        width: 100%;
        line-height: inherit;
        text-align: left;
    }

        .invoice-box table td {
            vertical-align: top;
        }

@media print {
    .invoice-box {
        box-shadow: none;
        border: none;
    }
}

.Table-subtitle {
    color: #999999;
    font-size: 0.92857rem;
}

.u-font-md {
    font-size: 1rem;
}

.u-font-sm {
    font-size: .95rem;
}

.u-font-xs {
    font-size: .87rem;
}

.u-font-lg {
    font-size: 1.2rem;
}

.Error-date {
    position: absolute;
    top: 10px;
    left: 15px;
}

.Compare-name {
    max-width: 180px;
}

.ProgressBar-wrapper {
    padding: 0 15px 15px 15px;
}

.ProgressBar-label {
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
}

.ProgressBar-trail {
    background-color: rgb(255, 237, 204);
    width: 100%;
    border-radius: 5px;
}

.ProgressBar-value {
    background-color: rgb(245, 128, 37);
    border-radius: 5px;
    height: 8px;
}

.ProgressBar-endlabel {
    min-width: 38px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: right;
}

.Hero-badge {
    background-color: #f1f1f1;
    border-radius: 6px;
    border: 1px solid #cccccc;
    padding: 20px;
}

.Header-select {
    font-size: 18px;
    padding-bottom: 0;
}

.Nav-item--shield a:hover,
.Nav-item--shield a.is-active,
.Nav-item--shield a.is-active:before {
    background-color: #e39153 !important;
}

.u-before {
    outline: none !important;
    border-color: #cae1f3 !important;
    box-shadow: inset 0px 0px 10px #cae1f3 !important;
    background-color: #f7fafc !important;
}

.u-after {
    outline: none !important;
    border-color: #b6f0ca !important;
    box-shadow: inset 0px 0px 10px #b6f0ca !important;
    background-color: #f7fcf7 !important;
}

.u-adjust {
    outline: none !important;
    border-color: #e39153 !important;
    box-shadow: inset 0px 0px 10px #e39153 !important;
    background-color: #f7fcf7 !important;
}

.u-bb-solid {
    border-bottom: 1px solid #eee;
}

.u-bordered {
    padding: 2px;
    border: 1px solid #eee;
    border-radius: 6px;
    text-align: center;
    margin-right: 8px;
}

.temp {
    background-color: #c6f3c6;
    color: #509bc2;
}

.Table-tr--before > td:first-child::before {
    background-color: #80a7cc;
}

.Table-tr--after > td:first-child::before {
    background-color: #a3dba3;
}

.u-table-clear td {
    border: none !important;
}

td.numeric, th.numeric {
    text-align: right !important;
    padding-right: 10px !important;
}

td.total, th.total {
    font-size: 14px !important;
    font-weight: 600 !important;
}

tfoot th.total,
tfoot tr.totals th {
    border-bottom-width: 1px !important;
}

tfoot th.total,
tfoot tr.totals th {
    border-top-width: 1px !important;
}

table.align-top th,
table.align-top td {
    vertical-align: top !important;
}

th.results-col-1 {
    width: 20% !important;
}

@media print {
    th {
        white-space: normal !important;
        overflow-wrap: break-word !important;
    }
}

.FilterPanel{
    position: relative;
}


.FilterBody{

    padding-right: 10px;
    padding-bottom: 30px;
}
.FilterGroup{
    background-color: #ffffff;
    border-radius: 8px;
    margin: 0 0 10px 0;
    padding: 15px;
}

.FilterModal{
    position: absolute;
    top: 100%;
    left: 0;
    bottom: 0;
    z-index: 100;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.20);
    transition: 'height 0.3s ease-in-out';
    background-color: #f1f1f1;
    min-width: 850px;
}

.FilterFooter{
    padding:20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}


.FilterRow-wrapper {
    display: flex;
    align-items: center;
    gap: 0;
    position: relative;
    width: 100%;
}

/* General styling for all selects */
.FilterRow-and,
.FilterRow-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #D3D3D3;
    padding: 5px 30px 5px 10px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    background-color: #FFF;
    color: #333;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='gray'%3E%3Cpath d='M8.12 9l3.88 3.88L15.88 9 17 10.12l-5 5-5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 20px;
}
.FilterRow-and {
    border-radius: 5px;
}

.FilterRow-select
{
    flex-grow: 1;
}
/* First select element: left border-radius */
.FilterRow-select:first-child {
    border-radius: 5px 0 0 5px;
    min-width: 200px;
}

/* Middle select element: no border-radius */
.FilterRow-select:nth-child(2) {
    border-radius: 0;
    border-left: none;
    /* Remove left border for smooth connection */
}

/* Last select element: right border-radius */
.FilterRow-select:last-child {
    border-radius: 0 5px 5px 0;
    border-left: none;
    min-width: 200px;
        /* Remove left border for smooth connection */
}

/* Hover effect for all selects */
.FilterRow-select:hover {
    border-color: #A9A9A9;
}



.RuleAndOr{
    margin-right: 10px;
    min-width: 70px;
    text-align: right;
}
.RiskTool-container{
    position: relative;
    min-width: 700px;
    min-height: 800px;
        padding: 5px;
    margin-bottom: 30px;
}

.RiskTool-content{
    padding-left: 20px;
    flex-grow: 1;
}

.RiskTool-map{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    min-height: 800px;
}   

.NameValueIcon {
    border: 1px solid #e5e9e7;
    border-radius:  20px;
    padding: 10px;
    min-width: 160px;
}

